import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../ConfigContext';

const NewLitteraRoom = () => {
    const config = useConfig()
    const [selectedFile, setSelectedFile] = useState(null);
    const { id } = useParams(); // Get the id from the URL params

    // Construct the file URL using the base path and id
    const getFileUrl = `${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_ASSIGNMENT_PATH}/${id}`;

    // Function to handle file selection (optional, in case you want to allow file uploads later)
    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    // Render the file without checking its type
    const renderFile = () => {
        // Attempt to render based on file extension
        const fileExtension = getFileUrl.split('.').pop().toLowerCase();

        // If it's an image (jpg, jpeg, png, gif), render an <img> tag
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            return (
                <div className="image-container">
                    <img src={getFileUrl} alt="Selected" className="w-full" />
                </div>
            );
        }

        // If it's a PDF, render it in an <iframe> or <embed> (allow browser to open PDF)
        if (fileExtension === 'pdf') {
            return (
                <div className="pdf-container">
                    <iframe
                        src={getFileUrl}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                    ></iframe>
                </div>
            );
        }

        // If it's a video (mp4, webm, ogg), render a <video> tag
        if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
            return (
                <div className="video-container">
                    <video controls className="w-full">
                        <source src={getFileUrl} type={`video/${fileExtension}`} />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        // If it's an unsupported file type, show a message
        return <div>Unsupported file type or file cannot be rendered</div>;
    };

    return (
        <div className="w-full border-b-2 h-fit bg-[#FFFFFF] overflow-x-hidden border-t-0">
            <div className="wrapper w-full flex">
                <div className="h-full w-full">
                    <div className="h-full w-full">
                        <div className="max-md:block flex">
                            <div className="max-md:h-fit h-full max-md:w-full w-full border-r-2">
                                <div className="h-fit relative w-full border-b-2 flex items-center justify-between py-1 px-2 gap-1">
                                    {/* Optionally add other UI components here */}
                                </div>

                                {/* Conditionally render the file */}
                                {renderFile()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewLitteraRoom;
