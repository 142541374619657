import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

const DraggableModal = ({ open, handleClose }) => {
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [initialOffset, setInitialOffset] = useState({ x: 0, y: 0 });
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the screen is mobile-sized
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600); // Define mobile threshold here
        };

        // Initial check
        handleResize();

        // Add resize event listener to update on screen size change
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Center the modal on initial load
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        setPosition({ x: centerX, y: centerY });
    }, []);

    const handleMouseDown = (e) => {
        setDragging(true);
        setInitialOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            setPosition({
                x: e.clientX - initialOffset.x,
                y: e.clientY - initialOffset.y
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const modalStyle = {
        display: open ? 'block' : 'none', // Toggle display based on open state
        position: 'absolute',
        top: position.y,
        left: isMobile ? '206px' : position.x, // Set left to 206px on mobile, else use center X position
        transform: 'translate(-50%, -50%)', // Center the modal properly
        // border: '2px solid #428bca',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        width: "80%", // Default width (for mobile responsiveness)
        maxWidth: '500px', // Max width for larger screens
        cursor: 'move',
        zIndex: 1000,
        boxSizing: 'border-box', // Ensure padding is included in width calculation
        // padding: '15px', // Padding for the modal content
    };

    return (
        <>
            <div
                className="draggable-modal"
                style={modalStyle}

            >
                <div className="modal-content aboutcontent" id="dvaboutlitteraheader" onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}>
                    <div className="modal-header aboutmodalheader11" style={{ backgroundColor: '#f9f9f9 ' }}>
                        <div className="aboutmodalheader" style={{ display: 'flex', justifyContent: 'space-between',padding:"5px",width:"100%" }}>
                            <h4 className="modal-title" style={{ padding: "5px" }}>
                                <span data-tag="About_littera">About littera</span>
                            </h4>
                            <button
                                aria-hidden="true"
                                data-dismiss="modal"
                                className="close"
                                type="button"
                                onClick={handleClose}
                            >
                                ×
                            </button>
                        </div>
                    </div>
                    <div className="modal-body thisbody">
                        <span data-tag="Last_updated_on">Last Updated On</span> : 14-May-2024
                    </div>
                    <div className="modal-footer aboutfooter">
                        <button type="button" className="btn btn-default aboutbtn" data-dismiss="modal" data-tag="Close" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DraggableModal;
