import React, { useEffect } from 'react'
import Breadcrumb from '../componentHome/commonComponent/Breadcrumbs';
import TrainingProgressReport from '../components/Content/TrainingDashboard/TrainingProgressReport';

const TrainingProgress = () => {
      
    const paths = [
        { title: 'Home', url: '/' },
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Training Progress Report', url: '/training-progress-report/:trainingid' }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return <>
        <Breadcrumb paths={paths} />
        <div className='p-8 max-sm:p-4'>
            <TrainingProgressReport/>
        </div>
    </>
}

export default TrainingProgress