import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="modal">
      <h2>Are you sure you want to log out?</h2>
      <button onClick={onConfirm}>OK</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};
export default LogoutModal;
