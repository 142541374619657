import React, { createContext, useContext, useState,useEffect } from 'react';

// Create a context object
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [client,setClient] = useState(null);
  const [selectedYear,setSelectedYear]= useState(null);
  const [selectedUserType, setSelectedUserType] = useState('');
  const [designationId, setDesignationId] = useState(null);
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [testDataOther, setTestDataOther] = useState(null);
  const [testalldata, setTestalldata] = useState(null);
  const [payload,setPayload] = useState(null);
  const [configDataurl, setConfigDataurl] = useState(null);
  const logoutUser = () => {
    setUser(null); // Clear user data
    // Optionally, you can perform additional logout actions (e.g., redirecting to login page)
  };
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const storedClient = JSON.parse(localStorage.getItem('clientData'));
   
    if (storedUser) {
      setUser(storedUser);
    }
    if (storedClient) {
      setClient(storedClient);
    }
    
  }, []);
  return (
    <UserContext.Provider 
    value={{ 
      user, setUser,
      logoutUser,
      client,setClient,
      selectedUserType, setSelectedUserType, 
      designationId, setDesignationId,
      selectedYear,setSelectedYear,
      startYear, setStartYear,
      endYear, setEndYear ,
      testDataOther, setTestDataOther ,
      testalldata, setTestalldata,
      payload,setPayload,
      configDataurl, setConfigDataurl
    }}>
      {children}
    </UserContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useUser = () => useContext(UserContext);
