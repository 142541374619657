import React, { useState, useEffect } from 'react';
import { Collapse, Modal, Button, Input, Dropdown, Menu, message, Tooltip, Badge } from 'antd';
import { LeftOutlined, RightOutlined, TagOutlined, CommentOutlined, PaperClipOutlined, FileTextOutlined, FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';
import axios from 'axios';
import styled from 'styled-components';  // Import styled-components
import { useConfig } from '../../ConfigContext';
import moment from 'moment';  // To format the date
import { NavLink } from 'react-router-dom';
const { Panel } = Collapse;
const UploadModal = ({ closeModal, participantId }) => {
    const config = useConfig()
    const [openPanels, setOpenPanels] = useState([]);
    const [filter, setFilter] = useState('All'); // Track the selected filter
    const [searchQuery, setSearchQuery] = useState('');
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const user = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState()
    const [searchCriteria, setSearchCriteria] = useState(''); // State for selected search criteria
    console.log("participantId", participantId)
    const [commentText, setCommentText] = useState({});
    const handleCommentChange = (e, uploadId) => {
        setCommentText(prevState => ({
            ...prevState,
            [uploadId]: e.target.value
        }));
    };

    const handleSendComment = (uploadId) => {
        const commentedby = user.userdetails.agencyid;  // Assuming the agency ID is the user ID
        const comment = commentText[uploadId];  // Get the comment text for this upload

        if (comment) {
            // Create the request body
            const requestBody = {
                taau_uploadid: uploadId,  // Upload ID for the current upload
                taau_commentedby: commentedby,  // The user ID who is commenting
                taau_createdon: new Date().toISOString().split('T')[0],  // Current date in YYYY-MM-DD format
                taau_comment: comment  // The actual comment text
            };

            // Send POST request to API using Axios
            axios.put(`${config.REACT_APP_API_URL}/Update_assignment_upload_comment?uploadid=${uploadId}`, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'APIKey': config.REACT_APP_API_KEY,  // Custom header for API key
                }
            })
                .then(response => {
                    message.success('Comment sent successfully');
                    // Optionally reset the comment input field
                    setCommentText(prevState => ({ ...prevState, [uploadId]: '' }));
                })
                .catch(error => {
                    console.error('Error sending comment:', error);
                    // Optionally handle the error case
                });
        }
    };


    const [data, setData,] = useState([])
    useEffect(() => {
        const participantid = user.userdetails.agencyid;
        const fetchData = async () => {
            const url = `${config.REACT_APP_API_URL}/Get_Uploads?assignmentid=${participantId}&PageNumber=${page}&PageSize=${pageSize}&participantid=${participantid}`;
            let payload = null;  // Initially set payload to null

            // If searchQuery has a value, include the searchCriteria in the payload
            if (searchQuery && searchQuery.trim() !== "") {
                payload = {
                    searchCriteria: [
                        {
                            column: searchCriteria,   // Search column
                            value: searchQuery,       // Search value
                            condition: '=',           // Search condition (can be 'like', '=', etc.)
                            nextoperator: 'Or',       // Next operator for multiple conditions
                        },
                    ],
                };
            }


            try {
                const response = await axios.post(url, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'APIKey': config.REACT_APP_API_KEY,
                    },
                });

                console.log("response.data", response.data);
                setData(response.data);
                setIsLoading(false);
            } catch (error) {

                setIsLoading(false);
            }
        };


        fetchData();
    }, [searchQuery]); // Add searchQuery and searchCriteria to dependencies

    // State to manage which upload is toggled
    const [activeUploadId, setActiveUploadId] = useState(null);
    const [activeCommentId, setActiveCommentId] = useState(null);

    // Toggle visibility of the upload files
    const handleUploadToggle = (uploadId) => {
        setActiveUploadId(activeUploadId === uploadId ? null : uploadId);
    };

    // Toggle visibility of the comments section
    const handleCommentToggle = (uploadId) => {
        setActiveCommentId(activeCommentId === uploadId ? null : uploadId);
    };

    const handleOk = () => {
        setComments([...comments, { date: new Date().toLocaleString(), text: newComment }]);
        setNewComment('');
        message.success('Comment Sent!');
    };

    const handleFilterChange = (value) => {
        setFilter(value);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (direction) => {
        setPage(page + (direction === 'next' ? 1 : -1));
    };

    const handleViewDocs = (url) => {
        window.open(url);
    };
    const handlePanelToggle = (key) => {
        // Toggle the open/close state of the panel
        setOpenPanels(prevState =>
            prevState.includes(key) ? prevState.filter(panel => panel !== key) : [...prevState, key]
        );
    };

    const filterMenu = (
        <Menu onClick={(e) => handleFilterChange(e.key)}>
            <Menu.Item key="All">
                <TagOutlined style={{ marginRight: "7px" }} /> All
            </Menu.Item>
            <Menu.Item key="Image">
                <FileImageOutlined style={{ marginRight: "7px" }} /> Image
            </Menu.Item>
            <Menu.Item key="Document">
                <FileTextOutlined style={{ marginRight: "7px" }} /> Document
            </Menu.Item>
            <Menu.Item key="Video">
                <VideoCameraOutlined style={{ marginRight: "7px" }} /> Video
            </Menu.Item>
        </Menu>
    );
    const getFilterButtonContent = () => {
        switch (filter) {
            case 'Image':
                return <FileImageOutlined style={{ marginLeft: "7px" }} />;
            case 'Document':
                return <FileTextOutlined style={{ marginLeft: "7px" }} />;
            case 'Video':
                return <VideoCameraOutlined style={{ marginLeft: "7px" }} />;
            default:
                return <TagOutlined style={{ marginLeft: "7px" }} />;
        }
    };
    return (

        <StyledModal visible={true} // Ensures the modal is visible
            onCancel={closeModal} // Calls closeModal when the cancel button is clicked
            footer={[
                // Custom footer with OK and Cancel buttons
                <Button key="cancel" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={closeModal}>
                    OK
                </Button>,
            ]}

        >

            <div className="modal-header">

                <h3>Upload</h3>

                {/* Left section with icons */}
                <div className="left-section" style={{ width: "100%" }}>
                    <div className="modalicon">
                        <Tooltip title="Filter">
                            <Dropdown overlay={filterMenu}>
                                <Button
                                    style={{ borderRadius: '50%', width: "35px", height: "35px", border: '1px solid #ccc', marginLeft: "4px" }}
                                >
                                    {getFilterButtonContent()} {/* Show the icon based on the selected filter */}
                                </Button>
                            </Dropdown>
                        </Tooltip>
                        <Tooltip title="Comments">
                            <Button
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#f5f5f5',
                                    width: "35px",
                                    height: "35px",
                                    border: '1px solid #ccc',
                                    marginLeft: "4px"
                                }}
                            >
                                <CommentOutlined style={{ fontSize: 16, color: '#428bca' }} />
                            </Button>
                        </Tooltip>
                    </div>
                    {/* Search Input */}
                    <div className="modalicon" style={{ marginLeft: "10px", marginTop: "10px" }}>
                        <Input
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            style={{ width: '100%', maxWidth: '250px', }}  // Full-width on small screens
                        />
                    </div>
                </div>

                {/* Right section with pagination */}
                <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: "end" }}>
                    <span >
                        Showing {page} - 2 of 2
                    </span>
                    <Button
                        icon={<LeftOutlined />}
                        onClick={() => handlePageChange('prev')}
                        style={{ marginRight: 5 }}
                    />
                    <Button
                        icon={<RightOutlined />}
                        onClick={() => handlePageChange('next')}
                    />
                </div>
            </div>
            <Collapse
                accordion
                activeKey={openPanels} // Set activeKey to control which panels are open
                onChange={setOpenPanels} // Update the openPanels state when collapse changes
                style={{ marginTop: "10px" }}>
                {data.map((item) => (
                    item.participant.map((participant, index) => (
                        <div key={participant.participantid} style={{ padding: '10px' }}>



                            <div>

                                <Panel
                                    header={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={require('../../../src/assest/user_img.png')} alt="user" style={{ width: 24, borderRadius: '50%', marginRight: 10 }} />
                                                <span>{participant.participantname}</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="View Attachments">
                                                    <Button icon={<PaperClipOutlined />} onClick={() => handlePanelToggle(index.toString())} style={{ border: "none" }} />
                                                </Tooltip>
                                                <span>
                                                    <Badge count={participant.uploadvalue.length} style={{ backgroundColor: '#FFA500' }} showZero />
                                                </span>
                                                <Tooltip title="Comments">
                                                    <Button icon={<CommentOutlined />} style={{ backgroundColor: '#f5f5f5', border: "none" }} />
                                                </Tooltip>
                                                <span>
                                                    <Badge
                                                        count={participant.uploadvalue.reduce((acc, upload) => acc + (upload.comment ? upload.comment.length : 0), 0)}
                                                        style={{ backgroundColor: '#FFA500' }}
                                                        showZero
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    key={index.toString()}
                                    onClick={() => handleUploadToggle(participant.participantid)}
                                >
                                </Panel>
                                {activeUploadId === participant.participantid && participant.uploadvalue.map((upload) => (
                                    <div key={upload.taau_uploadid} style={{ border: "1px solid #e7dddd", padding: "5px", borderRadius: "8px", margin: "5px" }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* Attachment icon */}
                                            <NavLink to={`/link-online/${upload.taau_uploadpath}`}>
                                                <span style={{ marginRight: '10px', fontSize: '18px', color: '#5e5e5e' }}>
                                                    <PaperClipOutlined />
                                                </span>
                                            </NavLink>
                                            {/* Title of Upload */}
                                            <h5 style={{ marginTop: '5px' }}>{upload.taau_title}</h5>
                                            <span
                                                className="fa fa-comment-o"
                                                title="Comment"
                                                style={{ marginLeft: "10px", cursor: 'pointer' }}
                                                onClick={() => handleCommentToggle(upload.taau_uploadid)}
                                            ></span>{upload.comment.length}
                                            <h6 style={{ marginTop: '5px', marginLeft: "10px", color: "#428bca", fontWeight: "bold", fontSize: "10px", cursor: "pointer" }} onClick={() => handleCommentToggle(upload.taau_uploadid)}>
                                                Message/Reply
                                            </h6>
                                        </div>

                                        {/* Toggle Comments */}
                                        <div>
                                            {activeCommentId === upload.taau_uploadid && (
                                                <>
                                                    {/* Show Textarea and Send button */}
                                                    <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', }}>
                                                        <textarea
                                                            placeholder="Write your message..."
                                                            value={commentText[upload.taau_uploadid] || ''}
                                                            onChange={(e) => handleCommentChange(e, upload.taau_uploadid)}
                                                            rows={4}
                                                            style={{ width: '100%', padding: '4px', borderRadius: '5px', border: '1px solid #ddd', height: "9vh" }}
                                                        />
                                                        <div style={{ textAlign: 'right', marginTop: '5px' }}>
                                                            <Button type="primary" onClick={() => handleSendComment(upload.taau_uploadid)}>
                                                                Send
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    {/* Show Existing Comments */}
                                                    {upload.comment.map((comment, index) => (
                                                        <div key={index} style={{ marginLeft: '20px' }}>
                                                            <p><strong>{comment.commentedbyname}</strong> - {moment(comment.taau_createdon).format('MMMM Do YYYY, h:mm A')}</p>
                                                            <p>{comment.taau_comment}</p>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    ))
                ))}
            </Collapse>

        </StyledModal>
    );
};

export default UploadModal;

// Styled Components for modal and layout
const StyledModal = styled(Modal)`
  .ant-modal {
    width: 55% !important;  /* Default width for larger screens */
  }
  .ant-collapse-header .ant-collapse-expand-icon {
    display: none !important;
  }
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .ant-modal {
      width: 90% !important;  /* Set width to 90% on mobile screens */
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .left-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

