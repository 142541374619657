import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Paper,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  Modal,
} from "@mui/material";
import image from "./image1_url.jpg";
import image2 from "./image2_url.jpg";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import screenfull from "screenfull";
import CountdownTimer from "./TimeCounter";
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from "../../UserContext";
import { v4 as uuidv4 } from 'uuid';
import { useConfig } from "../../ConfigContext";
// Utility function to strip HTML tags and get plain text
const stripHtmlTags = (html) => {

  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

function OnlineTest() {
  const location = useLocation()
  const { test, apiData } = location.state || {}; // Destructure state
  const TimeTwo = (test?.noOfQuestion * test?.assesmentTime) * 60
  const config = useConfig()
  const { testDataOther, testalldata, payload } = useUser();
  const navigate = useNavigate();
  console.log("Myoayload", payload)
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [markedForReview, setMarkedForReview] = useState(new Set());
  const [selectedOption, setSelectedOption] = useState(""); // State to track selected option

  const [answers, setAnswers] = useState({}); // State to track answered questions
  console.log("config", config)
  console.log("testalldata", testalldata)
  console.log("testDataOther1", testDataOther);
  console.log("payload", payload);
  const testTime = 600 * testalldata?.time_per_question || TimeTwo;
  const [remainingTime, setRemainingTime] = useState(testTime); // Initialize remaining time
  console.log("testTime", testTime); // Outputs the total time in second
  const testDataOther1 = testDataOther || apiData



  useEffect(() => {
    // Start a timer that counts down every second
    const timer = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer); // Stop the timer
          handleSubmit(); // Call submit function when time is up
          return 0; // Ensure it doesn't go negative
        }
        return prevTime - 1; // Decrease time by 1 second
      });
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timer);
  }, []);

  console.log("apiData", apiData)
  console.log("test", test)

  const user = JSON.parse(localStorage.getItem('user'));
  console.log("user", user);
  // Modal states
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Disable text selection
    const handleSelectStart = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("selectstart", handleSelectStart);
    document.addEventListener("copy", (event) => event.preventDefault());

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("selectstart", handleSelectStart);
      document.removeEventListener("copy", (event) => event.preventDefault());
    };
  }, []);
  const handleGoFullScreen = async () => {
    try {
      if (screenfull.isEnabled) {
        await screenfull.request();
      }
    } catch (error) {
      setErrorMessage("Permissions check failed. Please enable permissions.");
      // setModalOpen(true);
    }
  };

  const handleFullScreenChange = () => {
    if (!screenfull.isFullscreen) {
      handleGoFullScreen();
      setModalOpen(true);
    }
  };

  useEffect(() => {

    handleGoFullScreen(); // Request fullscreen mode on component mount
    setModalOpen(false);
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    // Event listener for visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Save the test and navigate to submit page when the tab is hidden
        handleSaveTest();
        handleSubmit();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentQuestion, selectedOption, answers, markedForReview]);

  const handleSaveTest = () => {
    // Logic to save the test progress
    console.log("Saving test progress...");
    // You might want to make an API call here to save the test progress
  };
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0'); // Ensures 2-digit format
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Ensures 2-digit format
    return `${hours}:${minutes}`;
  };

  const ThisId = uuidv4()
  // Usage
  const start_time = getCurrentTime();
  console.log(start_time); // e.g., "10:00"

  const getCurrentDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const start_date_time = getCurrentDateTime();
  const getCurrentDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  console.log(getCurrentDate())
  console.log("Finalpayload", payload)
  const payload_Sessionid = payload?.sessionid ? 3 : 2;
  console.log("finaltype", payload_Sessionid)
  const handleSubmit = async () => {
    // Always update answers for the current question
    if (selectedOption !== "") {
      setAnswers(prev => ({
        ...prev,
        [testDataOther1.questions[currentQuestion].questionID]: selectedOption,
      }));
    }

    const isLastQuestion = currentQuestion === testDataOther1.questions.length - 1;
    const lastAnswer = isLastQuestion ? selectedOption : answers[testDataOther1.questions[currentQuestion].questionID] || "00000000-0000-0000-0000-000000000000";

    // Prepare the test data object

    const testid = test?.testID || uuidv4()
    const testData = {
      test: {
        testID: testid, // Use existing testID or generate a new one
        testName: `Self Test-${start_date_time}`,
        trainingCategoryID: payload?.trainingCategory,
        testDescription: `Self Test-${start_date_time}`,
        assesmentTime: testTime || testData?.assesmentTime,
        noOfQuestion: testDataOther1.questions.length,
        createdOn: new Date().toISOString(),
        createdByAgencyID: user?.userdetails?.agencyid,
        isActive: true,
        mark_per_question: 1,
        questionDifficultyID: payload?.questionDifficutyID,
        start_time: start_time,
        type: payload_Sessionid,
        stpDetails: {
          docNum: testid,
          tttdsInfoDesc: "",
          docId: testid,
          createdOn: getCurrentDate(),
          createdBy: user?.userdetails?.agencyid,
          branchId: user?.userdetails?.branchid,
          docRemark: "",
          docDate: getCurrentDate(),
          actionDate: getCurrentDate(),
          fwdEmpId: user?.userdetails?.agencyid,
          tatTypeId: 0,
          docStatus: 0,
          createdByEmpId: user?.userdetails?.agencyid
        }
      },
      testQuestion: {
        testQuestionID: ThisId,
        testID: test?.testID || uuidv4(), // Use existing testID or generate a new one
        skillTag: payload?.skillSet || test?.skillTag,
        createdOn: getCurrentDate(),
        createdByAgencyID: user?.userdetails?.agencyid,
        isActive: true,
        training_TrainingID: payload?.trainingid,
        training_SessionID: payload?.sessionid,
      },
      trg_type: 2,
      test_Session_Date: getCurrentDate(),
      test_Session_Time: getCurrentDate(),
      test_Session_Day: 0,
      test_Session_week: 0,
      test_Session_no: "1",
      test_Session_module: "1"
    };

    try {
      // Check if the testID already exists
      if (!test?.testID) {
        // API call to save the test if testID doesn't exist
        const response = await fetch(`${config?.REACT_APP_EVAL_API_PATH}/Tests/TestSession`, {
          method: 'POST',
          headers: {
            "API-Key": config?.REACT_APP_EVAL_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(testData),
        });

        if (!response.ok) throw new Error('Failed to save test');
      }

      // Prepare results for each question, ensuring all answers are included
      const results = testDataOther1.questions.map((question, index) => {
        return {
          testRefrenceID: uuidv4(),
          partcipantID: user?.userdetails?.agencyid,
          testID: testData.test.testID || test?.testID, // Use the testID (existing or newly generated)
          questionID: question.questionID,
          answerID: question.questionID === testDataOther1.questions[testDataOther1.questions.length - 1].questionID
            ? lastAnswer
            : answers[question.questionID] || "00000000-0000-0000-0000-000000000000",
          testQuestionID: test?.testQuestionID || ThisId,
          testDate: getCurrentDate(),
          isAnsweredCorrect: false,
          questionTimeStart: getCurrentDate(),
          questionTimeEnd: getCurrentDate(),
          isActive: true,
          createdOn: getCurrentDate(),
          createdByAgencyID: user?.userdetails?.agencyid
        };
      });

      const resultData = {
        participentResults: results
      };

      // API call to save the test results
      const resultResponse = await fetch(`${config?.REACT_APP_EVAL_API_PATH}/ParticipantTestResults`, {
        method: 'POST',
        headers: {
          "API-Key": config?.REACT_APP_EVAL_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(resultData),
      });

      if (!resultResponse.ok) throw new Error('Failed to save test results');

      // Navigate to the submit page after successful submission
      navigate('/submit-test');

    } catch (error) {
      console.error('Error during submission:', error);
    }
  };



  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOk = () => {
    // Handle the cancel action, if necessary
    setModalOpen(false);
    handleGoFullScreen();
  };

  const handleNext = () => {
    if (selectedOption !== "") {
      setAnswers(prev => ({
        ...prev,
        [testDataOther1.questions[currentQuestion].questionID]: selectedOption,
      }));
    }
    if (currentQuestion < testDataOther1.questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(answers[testDataOther1.questions[currentQuestion + 1].questionID] || "");
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedOption(answers[testDataOther1.questions[currentQuestion - 1].questionID] || "");
    }
  };

  const handleMarkForReview = () => {
    const updatedSet = new Set(markedForReview);
    if (updatedSet.has(currentQuestion)) {
      updatedSet.delete(currentQuestion);
    } else {
      updatedSet.add(currentQuestion);
    }
    setMarkedForReview(updatedSet);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  if (!testDataOther1 || !testDataOther1.questions) return <div>Loading...</div>;

  const currentQuestionData = testDataOther1.questions[currentQuestion];
  const questionOptions = currentQuestionData.answers || [];



  return (
    <div
      style={{
        width: "100vw",
        height: window.innerWidth <= 767 ? "120vh" : "100vh",
        overflow: window.innerWidth <= 767 ? "scroll" : "hidden",
        margin: 0,
        padding: 0,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ fontSize: "larger" }}>Test Name - CAT Preparation</Typography>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={2}
        style={{ padding: 20, height: "calc(96% - 64px)", overflow: "hidden" }}
      >
        {/* Main Content */}
        <Grid item xs={12} md={8}>
          <Card style={{ height: "100%", position: "relative" }}>
            <CardContent>
              <Typography variant="h6" style={{ fontSize: "medium" }}>
                Question {currentQuestion + 1}
              </Typography>
              <Typography variant="body1" dangerouslySetInnerHTML={{ __html: stripHtmlTags(currentQuestionData.question1) }} />
              <RadioGroup
                value={selectedOption}
                onChange={handleOptionChange}
                style={{ marginTop: "10px" }}
              >
                {questionOptions[0].answerDescription.includes("<img") ? (
                  <Grid container spacing={2}>
                    {questionOptions.map((option, index) => (
                      <Grid item xs={6} key={index}>
                        <FormControlLabel
                          value={option.answerID}
                          control={<Radio />}
                          label={
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Box
                                component="img"
                                src={option.answerDescription.match(/src="([^"]*)"/)[1]}
                                alt={`Option ${option.answerID}`}
                                sx={{
                                  maxWidth: "143px",
                                  maxHeight: "143px",
                                  borderRadius: "10px",
                                  objectFit: "contain",
                                  marginBottom: "8px",
                                }}
                              />
                              <Typography>{`Option ${option.answerID}`}</Typography>
                            </Box>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  questionOptions.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.answerID}
                      control={<Radio />}
                      label={stripHtmlTags(option.answerDescription)}
                    />
                  ))
                )}
              </RadioGroup>
            </CardContent>
            <div
              style={{
                position: "absolute",
                bottom: "6px",
                right: 0,
                padding: "0 16px",
                backgroundColor: "#fff",
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePrevious}
                  disabled={currentQuestion === 0}
                >
                  Previous
                </Button>
                {currentQuestion === testDataOther1.questions.length - 1 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: 10 }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: 10 }}
                  onClick={handleMarkForReview}
                >
                  {markedForReview.has(currentQuestion)
                    ? "Unmark for review"
                    : "Mark for review"}
                </Button>
              </Box>
            </div>
          </Card>
        </Grid>
        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 20, height: "100%" }}>
            <Typography variant="h6" style={{ fontSize: "larger", textAlign: "center" }}>Time Left</Typography>
            <Typography variant="h4" color="error">
              <CountdownTimer initialTime={testTime} />
            </Typography>

            <Typography variant="h6" style={{ marginTop: 20 }}>
              {/* Quant */}
            </Typography>
            <Grid container spacing={1}>
              {testDataOther1.questions.map((q, i) => (
                <Grid item xs={2} key={i}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor:
                        i === currentQuestion
                          ? "blue"
                          : markedForReview.has(i)
                            ? "red"
                            : answers[q.questionID] !== undefined
                              ? "green"
                              : "white",
                      color:
                        i === currentQuestion ||
                          markedForReview.has(i) ||
                          answers[q.questionID] !== undefined
                          ? "white"
                          : "black",
                      minWidth: window.innerWidth <= 767 ? "50px" : "62px",
                    }}
                    onClick={() => {
                      setCurrentQuestion(i);
                      setSelectedOption(answers[testDataOther1.questions[i].questionID] || ""); // Restore selection for the question
                    }}
                  >
                    {i + 1}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      {/* Indicators */}
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        padding={{ xs: "2px", sm: "15px 100px" }}
        borderTop="1px solid #80808069"
        backgroundColor="#80808042"
        position="fixed"
        bottom={0}
        left={0}
      >
        <Box display="flex">
          <Brightness1Icon style={{ color: "blue", fontSize: "2rem" }} />
          <Typography style={{ marginLeft: "5px" }}>Current</Typography>
        </Box>
        <Box display="flex">
          <Brightness1Icon style={{ color: "green", fontSize: "2rem" }} />
          <Typography style={{ marginLeft: "5px" }}>Answered</Typography>
        </Box>
        <Box display="flex">
          <Brightness1Icon style={{ color: "red", fontSize: "2rem" }} />
          <Typography style={{ marginLeft: "5px" }}>Marked for Review</Typography>
        </Box>
        <Box display="flex">
          <Brightness1Icon style={{ color: "white", fontSize: "2rem" }} />
          <Typography style={{ marginLeft: "5px" }}>Not Answered</Typography>
        </Box>
      </Box>

      {/* Error Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => {
          /* Do nothing here */
        }}
      >
        <Box
          sx={{
            position: "fixed", // Changed to fixed to cover the viewport
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 0,
            p: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "90%", // Adjust as needed
              maxWidth: 600, // Optional: max-width to avoid overly large modals
              bgcolor: "background.paper",

              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2" color="red" style={{ fontSize: "1.5rem" }}>
              Alert
            </Typography>
            <Typography sx={{ mt: 2, fontSize: "1.3rem" }}>
              Don't try to change tab or exit full-screen between the exam, exam
              will be terminated.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
                width: "100%",
              }}
            >
              <Button variant="outlined" color="primary" onClick={handleOk}>
                OK
              </Button>
            </Box>
          </Box>
        </Box>

      </Modal>
    </div>
  );
}

export default OnlineTest;
