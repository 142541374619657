import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { FaFileExcel } from "react-icons/fa";
import { IoMdPrint } from "react-icons/io";
import { useUser } from "../../UserContext";
import { useConfig } from "../../ConfigContext";
import * as XLSX from 'xlsx';
import Breadcrumb from "../commonComponent/Breadcrumbs";
import axios from "axios";

function TestAnaliticMain() {
    const config = useConfig();
    const { selectedUserType, selectedYear } = useUser();
    const [categoryData, setCategoryData] = useState([]);
    const [testData, setTestData] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const [skillData1, setSkillData1] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState("category");
    const [selectedTestType, setSelectedTestType] = useState('summative');
    const [selectedReportType, setSelectedReportType] = useState('training');
    const user = JSON.parse(localStorage.getItem('user'));
    const useId = user?.userdetails.agencyid;
    const [categoryId, setCategoryId] = useState(null);
    const [testId, setTestId] = useState(null);
    const startDate = `${selectedYear.split('-')[0]}-04-01`;
    const endDate = `${selectedYear.split('-')[1]}-03-31`;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState()
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const testTypeMap = { summative: 1, self: 3 };
                const reportTypeMap = { training: 1, session: 2, test: 3 };

                const testType = testTypeMap[selectedTestType];
                const groupBy = reportTypeMap[selectedReportType];

                let url = `${config?.REACT_APP_API_URL}/TRAINING_TEST_ANALYTIC_DATA?usertype=${selectedUserType}&userid=${useId}&fromdate=${startDate}&todate=${endDate}&groupOn=${groupBy}&testtype=${testType}`;
                if (categoryId) {
                    url += `&trainingid=${categoryId}`;
                }
                if (testId) {
                    url += `&sessionid=${testId}`;
                }
                const response = await axios.get(url, {
                    headers: { APIKey: config?.REACT_APP_API_KEY },
                });

                console.log("API Response:", response.data); // Log the full response
                setData(response.data)

            } catch (error) {
                setError(error);
                console.error("Error fetching data:", error.response ? error.response.data : error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedOption, selectedTestType, selectedReportType]);
    const handlePrint = () => {
        let dataToRender = [];

        // Based on selected option, decide which data to use
        if (selectedOption === "test") {
            dataToRender = testData;  // Check that this is being set correctly
        } else if (selectedOption === "session") {
            dataToRender = data;  // Check that session data is set correctly
        } else if (selectedOption === "category") {
            dataToRender = categoryData;  // Check category data as well
        }

        console.log("Data to Print:", dataToRender); // Check what's inside

        // Define the headers based on report type
        const headers = selectedReportType === "training" ? ["Training", "Percentage(%)"] :
            selectedReportType === "session" ? ["Session", "Percentage(%)"] :
                ["Test", "Percentage(%)"];

        // Build the table header HTML
        const headerHTML = headers.map(header => `<th style="border: 1px solid #000; padding: 5px;">${header}</th>`).join('');

        // Build the table body HTML
        const bodyHTML = data.map(item => `
            <tr>
                <td style="border: 1px solid #000; padding: 5px;">${item.name}</td>
                <td style="border: 1px solid #000; padding: 5px;">${item.percentage ? item.percentage.toFixed(2) : '0.00'}</td>
            </tr>
        `).join('');

        // Check if the bodyHTML is generated properly
        console.log("Table Body HTML:", bodyHTML);

        // Wrap the content in a complete HTML document structure
        const formattedContent = `<!DOCTYPE html>
        <html>
            <head>
                <meta charset="utf-8" />
                <title>Print Document</title>
                <style>
                    table { border-collapse: collapse; width: 100%; }
                    th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                    th { background-color: rgb(66, 139, 202); color: white; }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>${headerHTML}</tr>
                    </thead>
                    <tbody>${bodyHTML}</tbody>
                </table>
            </body>
        </html>`;

        // Open a new window to print the content
        const printWindow = window.open('', '_blank');
        printWindow.document.write(formattedContent);
        printWindow.document.close();
        printWindow.print();
    };
    useEffect(() => {
        console.log("Selected Option:", selectedOption);
    }, [selectedOption]);


    const renderTable = () => {
        let dataToRender = [];
        switch (selectedOption) {
            case "test":
                dataToRender = skillData;
                break;
            case "session":
                dataToRender = testData;
                break;
            case "training":
                dataToRender = categoryData;
                break;
            default:
                break;
        }

        console.log("Data to Render:", dataToRender); // Log entire data to render

        const headers = (() => {
            if (selectedReportType === "training") {
                return ["Training", "Percentage(%)"];
            } else if (selectedReportType === "session") {
                return ["Session", "Percentage(%)"];
            } else if (selectedReportType === "test") {
                return ["Test", "Percentage(%)"];
            }
            return [];
        })();



        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map(header => (
                                <TableCell key={header} style={{ backgroundColor: "rgb(66, 139, 202)", color: "white", fontSize: "1.5rem" }}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow
                                key={item.id}
                                onClick={() => {
                                    if (selectedReportType === "training") {
                                        handleCategoryClick(item);  // For Training Wise
                                    } else if (selectedReportType === "session") {
                                        handleSessionClick(item);  // For Session Wise
                                    }
                                }}
                            >
                                <TableCell className="underlined" style={{ fontSize: "1.4rem", textDecoration: "underline", cursor: "pointer" }}>
                                    {item.name}
                                </TableCell>
                                <TableCell style={{ fontSize: "1.4rem" }}>
                                    {item.percentage ? item.percentage.toFixed(2) : '0.00'}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        );
    };

    const handleCategoryClick = (item) => {
        console.log("Selected Training:", item); // Log the clicked training item
        // Automatically switch to 'session' report type when a training is clicked
        setSelectedReportType("session");
        setSelectedOption("session"); // Optionally, change the selected option here (optional)
        setCategoryId(item.key); // You may want to store the selected training ID
    };

    const handleSessionClick = (item) => {
        console.log("Selected Session:", item); // Log the clicked session item
        // Automatically switch to 'test' report type when a session is clicked
        setSelectedReportType("test");
        setSelectedOption("test"); // Optionally, change the selected option here (optional)
        setTestId(item.key); // You may want to store the selected session ID
    };


    const handleDownloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const wsData = [["Sl no.", "Category", "Test", "Question", "Skill Tags", "Max Marks", "Actual Marks", "Your Answer", "Correct Answer"]];

        skillData1.forEach((item, index) => {
            wsData.push([
                index + 1,
                item.trainingCategoryName,
                item.testDescription,
                item.question,
                item.skilltag,
                item.maxMarks,
                item.actualMarks,
                item.youranswer,
                item.correctAnswerDescription || '0'
            ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!cols'] = [{ wpx: 50 }, { wpx: 150 }, { wpx: 150 }, { wpx: 250 }, { wpx: 150 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 150 }];
        XLSX.utils.book_append_sheet(wb, ws, "Competency Report");
        XLSX.writeFile(wb, "Competency_Report.xlsx");
    };
    if (isLoading) return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
    </div>);
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <Breadcrumb paths={[{ title: 'Dashboard', url: '/dashboard' }, { title: 'Self Test', url: '' }]} />
            <Row style={{ border: "2px solid #ddd2d2", width: "99%", margin: "auto", marginTop: "0px", borderRadius: "5px", padding: "8px" }}>
                <Col md={12} style={{ backgroundColor: "white", display: "flex", alignItems: "center", border: "1px solid #ddd2d2", borderRadius: "5px" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <span>Test Type : </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check
                            type="radio"
                            name="testType"
                            value="summative"
                            checked={selectedTestType === "summative"}
                            onChange={() => {
                                setSelectedTestType("summative");
                                setSelectedReportType("training")
                                setCategoryId(null); // Reset categoryId when test type changes
                                setTestId(null);     // Reset testId when test type changes
                            }}
                            style={{ marginRight: "8px" }}
                        />
                        <span>Summative Test</span>

                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check
                            type="radio"
                            name="testType"
                            value="self"
                            checked={selectedTestType === "self"}
                            onChange={() => {
                                setSelectedTestType("self");
                                setSelectedReportType("training")
                                setCategoryId(null); // Reset categoryId when test type changes
                                setTestId(null);     // Reset testId when test type changes
                            }}
                            style={{ marginRight: "8px" }}
                        />
                        <span>Self Test</span>
                    </div>
                </Col>

                <Col md={12} style={{ backgroundColor: "white", display: "flex", alignItems: "center", border: "1px solid #ddd2d2", borderRadius: "5px", marginTop: "10px", flexWrap: "wrap" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <span>Report Type : </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check type="radio" name="reportType" value="training" checked={selectedReportType === "training"} onChange={() => setSelectedReportType("training")} style={{ marginRight: "8px" }} />
                        <span>Training Wise</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check
                            type="radio"
                            name="reportType"
                            value="session"
                            checked={selectedReportType === "session"}
                            onChange={() => setSelectedReportType("session")}
                            disabled // Disable this radio button
                            style={{ marginRight: "8px" }}
                        />
                        <span>Session Wise</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check
                            type="radio"
                            name="reportType"
                            value="test"
                            checked={selectedReportType === "test"}
                            onChange={() => setSelectedReportType("test")}
                            disabled // Disable this radio button
                            style={{ marginRight: "8px" }}
                        />
                        <span>Test Wise</span>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <span
                            style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "none", // Remove underline by default
                            }}
                            onMouseEnter={(e) => e.target.style.textDecoration = "underline"} // On hover, add underline
                            onMouseLeave={(e) => e.target.style.textDecoration = "none"} // On leave, remove underline
                        >
                            Show All Sessions
                        </span>|
                        <span
                            style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "none", // Remove underline by default
                            }}
                            onMouseEnter={(e) => e.target.style.textDecoration = "underline"} // On hover, add underline
                            onMouseLeave={(e) => e.target.style.textDecoration = "none"} // On leave, remove underline
                        >
                            Clear All Filter
                        </span>
                    </div>

                </Col>

                <Col md={12} style={{ padding: "15px" }}>
                    <Col className="d-flex align-items-center" style={{ padding: "13px", justifyContent: "space-between", display: "flex" }}>
                        <p className="mb-0">{selectedTestType.charAt(0).toUpperCase() + selectedTestType.slice(1)} Test Analytics</p>
                        <div style={{ display: "flex" }}>
                            <FaFileExcel className="ms-2" style={{ fontSize: "1.3rem", cursor: "pointer" }} onClick={handleDownloadExcel} />
                            <IoMdPrint className="ms-2" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={handlePrint} />
                        </div>
                    </Col>
                    {renderTable()}
                </Col>
            </Row>
        </>
    );
}

export default TestAnaliticMain;
