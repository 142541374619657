import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../ConfigContext';
import { useFeedback } from '../../Context/FeedbackContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { FaRegComment } from 'react-icons/fa';
import { MdClose, MdOutlineReply } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { RiArrowUpWideFill } from "react-icons/ri"
const Comments = ({ showComments }) => {
  const [textarea, setTextarea] = useState('');
  const [messages, setMessages] = useState([]);
  const [replyText, setReplyText] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const [replys, setReplys] = useState(null);

  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const config = useConfig();
  const {
    selectedSessionId,
    Comments,
    setComments,
    Loading,
    setLoading
  } = useFeedback();

  useEffect(() => {
    getSessionComments();
    console.log(showComments);
    console.log('Comments', Comments);
  }, [showComments]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (replyingTo !== null) {
        submitReply(e);
      } else {
        createComments(e);
      }
    }
  };

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  }

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const getSessionComments = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/comment`, {
          headers: headers,
          params: {
            userid: user?.userdetails?.agencyid,
            sessionid: selectedSessionId,
            trainingid: id
          }
        });

        console.log('Session Comments', data);
        setComments(data);
        setLoading(false);

      } catch (error) {
        console.log('Error fetching session Comments', error);
        setLoading(false);
      }
    } else {
      console.log('No id provided');
      setLoading(false);
    }
  };

  const createComments = async (e) => {
    e.preventDefault();

    if (textarea.trim() === '') {
      toast.error('Add some input');
      return;
    }

    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    const postData = {
      tttcm_commentid: uuidv4(),
      tttcm_trg_id: id,
      tttcm_session_id: selectedSessionId,
      tttcm_created_by: user?.userdetails?.agencyid,
      tttcm_comment: textarea,
      tttcm_is_delete: 0,
      tttcm_createdon: getCurrentDateTime(),
    };

    try {
      const { data } = await axios.post(`${config?.REACT_APP_API_URL}/comment`, postData, { headers: headers });

      getSessionComments();
      setLoading(false);
      toast.success('Comment saved successfully.');
      setTextarea('');
    } catch (error) {
      console.error('Error posting session Comments:', error);
    } finally {
      setLoading(false);
      setTextarea('');
    }
  };

  const submitReply = async (e) => {
    e.preventDefault();

    if (replyText.trim() === '') {
      toast.error('Add some input for reply');
      return;
    }

    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    const postData = {
      tttcr_replyid: uuidv4(),
      tttcm_commentid: replyingTo?.tttcm_commentid,
      tttcr_tttcm_commentid: replyingTo?.tttcm_commentid,
      tttcr_comment: replyText,
      tttcr_replied_by: user?.userdetails?.agencyid,
      tttcr_is_delete: 0,
      tttcr_createdon: getCurrentDateTime(),
    };

    try {
      const { data } = await axios.post(`${config?.REACT_APP_API_URL}/Comment_Reply`, postData, { headers: headers });

      console.log('Reply posted successfully:', data);
      toast.success('Reply saved successfully.');
      getSessionComments();
      setLoading(false);
      setReplyText('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Error posting reply:', error);
    } finally {
      setLoading(false);
      setReplyText('');
      setReplyingTo(null);
    }
  };


  useEffect(() => {
    console.log("comment", replys);
  }, [replys]);

  return (
    <div className='p-2 datadiv max-md:h-full  flex flex-col items-center w-full border-b-2 gap-2 '>
      <h4 className='uppercase text-2xl'>Comments</h4>

      <div className='w-full max-md:max-h-[100vh] items-center overflow-y-auto flex flex-col gap-2 commentswrapper border-2 p-2 '>
        {Comments?.length > 0 ? (
          Comments.map((comment, index) => (
            <>
            <div key={index} className='w-full   border-2 px-2  flex-shrink-0 flex flex-col justify-center'>
              <h6 className='text-lg border-b-2 pb-2'>
                Message by {comment?.agencyName} on {formattedDate(comment.tttcm_createdon)}
              </h6>
              <div className='flex justify-between items-center'>
                <h5 className='text-gray capitalize'>{comment?.tttcm_comment}</h5>

                <div className='flex items-center justify-end gap-4'>
  
                    {replys === comment ? (
                      <RiArrowUpWideFill
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content='Hide Replies'
                        data-tooltip-place='top'
                        cursor={'pointer'}
                        fontSize={20}
                        onClick={() => {
                          setReplys(null);
                        }}
                      />
                        // null                      
                    ) : (
                    <div
                      className='flex  items-center gap-1'
                      onClick={() => {comment.comments.length === 0 ? setReplys(null) : setReplys(comment)}}
                      style={{ cursor: 'pointer' }}
                    >
                      <FaRegComment
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content={comment.comments.length === 0 ? "No Comments" : "Comments"}
                        data-tooltip-place='top'
                        fontSize={16}
                      />
                      {comment.comments.length}                  
                    </div>
                  )}  

                  {replyingTo === comment ? (
                    <MdClose
                      data-tooltip-id='my-tooltip'
                      data-tooltip-content='Close'
                      data-tooltip-place='top'
                      cursor={'pointer'}
                      fontSize={20}
                      onClick={() => {
                        setReplyingTo(null);
                        setReplyText('');
                      }}
                    />
                    
                  ) : (
                    <MdOutlineReply
                      data-tooltip-id='my-tooltip'
                      data-tooltip-content='Reply'
                      data-tooltip-place='top'
                      cursor={'pointer'}
                      fontSize={20}
                      onClick={() => setReplyingTo(comment)}
                    />
                  )}  

                  <Tooltip id='my-tooltip' />
                </div>
              </div>

              {replyingTo === comment && (

                <div className='flex items-center justify-between my-2 '>
                  <input
                    type='text'
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder='Write your reply...'
                    className='messagetextarea border-2 px-2 w-[75%] h-12'
                  />
                  <button
                    onClick={submitReply}
                    className='max-md:h-8 h-full flex text-sm items-center justify-center px-4 w-20 text-white bg-[#007bff] rounded-md'
                  >
                    Reply
                  </button>
                </div>
              )}
              {replys === comment && (
                <div className='h-[.5px] w-full bg-[#80808054]'>
                  
                </div>
              )}
              

              {replys === comment && (
                replys.comments.map((comment, index) => (
                  <div key={index} className='w-full cmt px-4 flex-shrink-0 flex flex-col justify-center pb-2 '>
                    <h6 className='text-lg '>
                      Reply from {comment?.agencyName} on {formattedDate(comment?.tttcr_createdon)}
                    </h6>
                    {comment?.tttcr_comment}
                  </div>
                ))
              )}

              </div>
              
            </>
          ))
        ) : (
          <h1 className='capitalize text-xl'>Comments are empty...</h1>
        )}
      </div>

      <div className='w-full border-t-2 pt-2 flex justify-between items-center'>
        <input
          type='text'
          value={textarea}
          onKeyDown={handleKeyPress}
          onChange={(e) => setTextarea(e.target.value)}
          className='messagetextarea border-2 px-2 w-[75%] h-16'
          placeholder='Enter Your Message...'
        />
        <button
          onClick={createComments}
          type='submit'
          className='max-md:h-14 h-[80%] px-4 w-24 text-white bg-[#007bff] rounded-md'
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Comments;