// import React, { useEffect, useRef } from 'react';
// import { getDocument } from 'pdfjs-dist';
// import 'pdfjs-dist/build/pdf.worker.entry.js';
// import { useConfig } from '../../ConfigContext';


// const PdfViewer = ({ pdf }) => {
//   const config = useConfig()
//   const containerRef = useRef();
//   const isRendering = useRef(false);
//   useEffect(() => {
//     if (isRendering.current) return;
//     const renderPdf = async () => {
//       const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//       if (!url) return;
//       isRendering.current = true;
//       containerRef.current.innerHTML = '';
//       try {
//         const pdf = await getDocument(url).promise;
//         console.log(`PDF loaded: ${pdf.numPages} pages`);
//         for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
//           const page = await pdf.getPage(pageNumber);
//           console.log(`Rendering page ${pageNumber}`);
//           const viewport = page.getViewport({ scale: 1.1 });
//           const canvas = document.createElement('canvas');
//           const context = canvas.getContext('2d');
//           canvas.width = viewport.width;
//           canvas.height = viewport.height;
//           const renderContext = {
//             canvasContext: context,
//             viewport: viewport,
//           };
//           await page.render(renderContext).promise;
//           containerRef.current.appendChild(canvas);
//         }
//       } catch (error) {
//         console.error('Error rendering PDF', error);
//       } finally {
//           isRendering.current = false;
//       }
//     };
//     renderPdf();
//   }, [pdf]);
//   return (
//     <div className='h-full w-full flex justify-center'>
//       <div className='h-full w-full' ref={containerRef}></div>
//     </div>
//   );
// };
// export default PdfViewer;

// import React from 'react';
// import { useConfig } from '../../ConfigContext';


// const PdfViewer = ({ pdf }) => {
//   const config = useConfig()
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;
//   return (
//     <div className='h-[100%] w-[100%] flex justify-center'>
//       <div className='h-full w-full' >
//         <iframe src={url} className='h-full w-full' frameborder="0"></iframe>
//       </div>
//     </div>
//   );
// };
// export default PdfViewer;


// import React, { useEffect, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const iframeRef = useRef(null);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       // Check if Ctrl (or Cmd) + S is pressed
//       if ((event.ctrlKey || event.metaKey) && event.key === 's') {
//         event.preventDefault();
//       }
//     };

//     // Add event listener on mount
//     window.addEventListener('keydown', handleKeyDown);

//     // Also add the event listener to the iframe itself (if it's present)
//     if (iframeRef.current) {
//       const iframeDocument = iframeRef.current.contentWindow.document;
//       iframeDocument.addEventListener('keydown', handleKeyDown);
//     }

//     // Cleanup on unmount
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//       if (iframeRef.current) {
//         const iframeDocument = iframeRef.current.contentWindow.document;
//         iframeDocument.removeEventListener('keydown', handleKeyDown);
//       }
//     };
//   }, []);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         <iframe
//           ref={iframeRef}
//           src={url}
//           className="h-full w-full"
//           frameBorder="0"
//           title="PDF Viewer"
//         ></iframe>
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;



import React, { useEffect, useRef } from 'react';
import { useConfig } from '../../ConfigContext';
import { useFeedback } from '../../Context/FeedbackContext';

const PdfViewer = ({ pdf }) => {
  const config = useConfig();
  const iframeRef = useRef(null);
  const { tabs } = useFeedback();

  const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

  // Unique key to store scroll position for each PDF
  const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(url)}`;
  console.log("pdfdata ", pdf)
  console.log("tabs ", tabs)

  // Save scroll position when message is received from iframe
  const handleMessage = (event) => {
    if (event.origin !== config?.LITTERA_CDN_BASE_URL) return; // Check origin for security
    if (event.data?.scrollPosition !== undefined) {
      localStorage.setItem(pdfScrollKey, event.data.scrollPosition.toString());
    }
  };

  useEffect(() => {
    // Listen for postMessage from iframe
    window.addEventListener('message', handleMessage);

    return () => {
      // Cleanup listener on component unmount
      window.removeEventListener('message', handleMessage);
    };
  }, [pdfScrollKey, url]);

  return (
    <div className="h-[100%] w-[100%] flex justify-center">
      <div className="h-full w-full">
        <iframe
          ref={iframeRef}
          src={url}
          className="h-full w-full"
          frameBorder="0"
          title="PDF Viewer"
        ></iframe>
      </div>
    </div>
  );
};

export default PdfViewer;


// import React, { useEffect, useRef, useState } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const iframeRef = useRef(null);
//   const { tabs } = useFeedback();

//   // State to track multiple iframes and their URLs
//   const [pdfIframes, setPdfIframes] = useState([]);

//   // Construct the PDF URL
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

//   // Scroll key for this specific PDF
//   const pdfScrollKey = `pdfScrollPosition_${pdf[0]?.ttsad_ttsam_id}`;

//   // Handle receiving scroll position from iframe
//   const handleMessage = (event) => {
//     if (event.origin !== config?.LITTERA_CDN_BASE_URL) return; // Check origin for security

//     // If the iframe sends its scroll position, store it
//     if (event.data?.scrollPosition !== undefined && event.data?.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id) {
//       localStorage.setItem(pdfScrollKey, event.data.scrollPosition.toString());
//     }
//   };

//   console.log('pdfIframes' , pdfIframes)

//   // Add event listener to listen for iframe scroll messages
//   useEffect(() => {
//     window.addEventListener('message', handleMessage);

//     return () => {
//       window.removeEventListener('message', handleMessage);
//     };
//   }, []);

//   // Check if this PDF iframe is already added to state
//   useEffect(() => {
//     const existingTab = tabs.find((tab) => tab.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id);

//     // If the PDF is not in the state, add it
//     if (existingTab && !pdfIframes.some((iframe) => iframe.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id)) {
//       setPdfIframes((prevIframes) => [
//         ...prevIframes,
//         { ttsad_ttsam_id: pdf[0]?.ttsad_ttsam_id, url },
//       ]);
//     }
//   }, [tabs, pdf, pdfIframes, url]);

//   // Function to send scroll position to iframe
//   const sendScrollPositionToIframe = (iframe) => {
//     const scrollPosition = localStorage.getItem(pdfScrollKey);
//     if (iframe && scrollPosition) {
//       iframe.contentWindow.postMessage(
//         { action: 'restoreScrollPosition', scrollPosition },
//         config?.LITTERA_CDN_BASE_URL
//       );
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         {/* Render each iframe separately based on ttsad_ttsam_id */}
//         {pdfIframes.map((iframeData) => (
//           iframeData.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id ? (
//             <iframe
//               key={iframeData.ttsad_ttsam_id}
//               src={iframeData.url}
//               className="h-full w-full"
//               frameBorder="0"
//               title={`PDF Viewer - ${iframeData.ttsad_ttsam_id}`}
//               ref={(iframe) => {
//                 iframeRef.current = iframe;
//                 sendScrollPositionToIframe(iframe); // Send the scroll position when iframe is loaded
//               }}
//             ></iframe>
//           ) : null
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;
