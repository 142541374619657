import React, { useEffect, useState } from 'react'
import { FaCertificate } from "react-icons/fa"
import { BsTable } from "react-icons/bs"
import { TbCheckbox } from "react-icons/tb"
import { MdPendingActions , MdOutlineAssessment } from "react-icons/md"
import { IoIosTimer, IoIosVideocam } from "react-icons/io"
import { LuCalendarDays } from "react-icons/lu"
import { useConfig } from '../../../ConfigContext'
import { useUser } from '../../../UserContext'
import { useFeedback } from '../../../Context/FeedbackContext'
import axios from 'axios'
import Loader from '../../../pages/Loader'
import DashboardTrainings from './DashboardTrainings'
import { useTheme } from '../../../ThemeProvider'
import { CircularProgress, useMediaQuery } from '@mui/material'

const TrainingsDashboard = () => {
    const config = useConfig()
    const { theme } = useTheme()
    const [searchQuery, setsearchQuery] = useState('');
    const isMobile = useMediaQuery("(max-width: 640px)");
    // const isMobile = useMediaQuery({ query: '(min-width: 768px)' });    
    const [searchCriteria, setsearchCriteria] = useState('trainingcode');
    const [PageNumber, setPageNumber] = useState(1)
    const [PageSize, setPageSize] = useState(10)
    const { 
        loading ,
        setLoading , 
        dashboard_analytics, 
        setdashboard_analytics,
        activeButton, setActiveButton ,
        dashboard_Data, setdashboard_Data ,
        isActive,
        dashboardpagesize, setdashboardpagesize,
        dashboardpagenumber, setdashboardpagenumber
    } = useFeedback()
    const [pendingTests, setPendingTests] = useState([]);
    const { 
        selectedYear , selectedUserType, 
    } = useUser()
    const user = JSON.parse(localStorage.getItem('user'));

    const handleToggle = (buttonType) => {
      setActiveButton(buttonType); 
      setdashboardpagenumber(1)
      setsearchQuery('')
    };
  
    const cardsdata = [
        {
            id:"1",
            title : "Completed",
            Logo : <TbCheckbox fontSize={30}/>,
            background: "bg-[#5CB85C]",
            numbers: dashboard_analytics?.trg_completed
        },
        {
            id:"2",
            title : "Certificate",
            Logo : <FaCertificate fontSize={30}/>,
            background: "bg-[#337AB7]",
            numbers: dashboard_analytics?.no_of_certificates
        },
        {
            id:"3",
            title : "Upcoming Training",
            Logo : <BsTable fontSize={30}/>,
            background: "bg-[#4E5154]",
            numbers:dashboard_analytics?.upcoming_trg
        },
        {
            id:"4",
            title : "Pending Test",
            Logo : <MdPendingActions fontSize={30}/>,
            background: "bg-[#5BC0DE]",
            // numbers:dashboard_analytics?.no_of_pending_tests
            numbers:pendingTests
        },
        {
            id:"5",
            title : "Pending Assesment",
            Logo : <MdOutlineAssessment fontSize={30}/>,
            background: "bg-[#F0AD4E]",
            numbers:dashboard_analytics?.no_of_pending_assignment
        },
    ]
    
    const getDashboardAnalytics = async () => {
        if (user) {
            setLoading(true);
            const headers = {
                Accept: 'application/json, text/plain',
                APIKey: config?.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
            };
    
            try {
                const startDate = `${selectedYear.split('-')[0]}-04-01`;
                const endDate = `${selectedYear.split('-')[1]}-03-31`;

                const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Dashboard_analytics`, {
                    headers: headers,
                    params: {
                        usertype: selectedUserType,
                        userid: user?.userdetails?.agencyid,
                        startdate: startDate,
                        enddate: endDate,
                    }
                });
                setdashboard_analytics(data);
                setLoading(false);
    
            } catch (error) {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const getPendingTests = async () => {

        const headers = {
        Accept: 'application/json, text/plain',
        'API-Key': config?.REACT_APP_EVAL_API_KEY,
        'Content-Type': 'application/json',
        };

        try {
        const { data } = await axios.get(`${config?.REACT_APP_EVAL_API_PATH}/Tests/UserTests`,
            {
            headers,
            params: {
                usertype:selectedUserType ,
                userid: user?.userdetails?.agencyid,
                PageNumber:1,
                PageSize:100000000
            }
        });
        return data;
        } catch (error) {
        console.log('Error fetching sessions', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          const data = await getPendingTests();  // Assuming this function returns the data
    
          // Filter data based on the conditions
          const filteredData = data?.filter(item => 
            item?.tdds_status === 1 && item?.participantstatus === null
          );
    
          console.log('filteredData' , filteredData?.length)
          // Set the filtered data to the state
          setPendingTests(filteredData?.length);
        };
    
        fetchData();
    }, []);
    
    useEffect(() => {
        setdashboardpagenumber(1)
        setActiveButton('myTrainings')
    }, [])

    const getDashboardData = async (input,criteria) => {
        console.log("inputdata" , input)
        if (user) {
            setLoading(true);
            const headers = {
                Accept: "application/json, text/plain",
                APIKey: config.REACT_APP_API_KEY,
                "Content-Type": "application/json",
            };

            const startdate = `${selectedYear.split('-')[0]}-04-01`
            const enddate = `${selectedYear.split('-')[1]}-03-31`

            const payload = {

                searchCriteria: [
                    {
                        column: 'trainingcode',
                        value: input,
                        condition: '=',
                        nextoperator: 'AND',
                    },
                ],
            };

            try {
                // Define the URLs
                const searchdashboardataurl = `${config?.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${1}&PageSize=${dashboardpagesize}`;
                const searchallDashboardDataurl = `${config?.REACT_APP_API_URL}/Dashboard_All_Trg_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${1}&PageSize=${dashboardpagesize}`;
                const dashboardataurl = `${config?.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${dashboardpagenumber}&PageSize=${dashboardpagesize}`;
                const allDashboardDataurl = `${config?.REACT_APP_API_URL}/Dashboard_All_Trg_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${dashboardpagenumber}&PageSize=${dashboardpagesize}`;
                
                // Determine which URL to hit based on payload and activeButton
                let url = '';
                let requestData = null;
            
                if (searchQuery === '' || input === undefined) {
                    console.log('inputmein data nahi hai')
                    // If no payload (search query empty or input undefined), fallback to regular URLs
                    url = activeButton === 'myTrainings' ? dashboardataurl : allDashboardDataurl;
                } else {
                    console.log('inputmein data hai')
                    // If payload exists (search query or input is defined), use search URLs
                    url = activeButton === 'myTrainings' ? searchdashboardataurl : searchallDashboardDataurl;
                    requestData = payload ? payload : null;  // Only attach payload if it exists
                }
            
                // Make the API request with the chosen URL and requestData (which can be null if no payload)
                const { data } = await axios.post(url, requestData, {
                    headers: headers,
                });
            
                // Update state with the response data
                setdashboard_Data(data);
                setLoading(false);
            } catch (error) {
                // Handle error
                setLoading(false);
                console.error(error); // Optionally log the error
            }

        } else {
            setLoading(false);
        }
    };

    const handlePaste = async (e) => {
        e.preventDefault();  // Prevent default paste behavior
    
        // Get pasted value and sanitize it
        let pastedValue = e.clipboardData.getData('Text'); 
        pastedValue = pastedValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    
        console.log("Pasted Value:", pastedValue);  // Debugging log
        setsearchQuery(pastedValue);  // Update state with sanitized pasted value
    
        // Trigger function when pasted value reaches 8 characters
        if (pastedValue.length === 8) {
            console.log("Calling function with pasted value:", pastedValue);  // Debugging log
            await getDashboardData(pastedValue, searchCriteria);  // Pass pasted value directly
        } else if (pastedValue === '') {
            console.log("Empty pasted value, calling getDashboardData");
            await getDashboardData('');  // Pass empty string if pasted value is empty
        }
    };

    // const handleInputChange = async (e) => {
    //     const value = e.target.value.replace(/[^0-9]/g, '');
    //     setsearchQuery(value);
    //     if (value.length === 8) {
    //       await yourFunction(value, searchCriteria);
    //     } else if (value === '') {
    //       await getDashboardData(); // Call when input is blank
    //     }
    // };

    const handleInputChange = async (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');  // Sanitize input
    
        setsearchQuery(value);  // Update the state with the sanitized value
    
        // Check if the value has 8 characters (for calling yourFunction or getDashboardData)
        if (value.length === 8) {
            console.log("Calling yourFunction with value:", value);  // Debugging log
            await yourFunction(value, searchCriteria);  // Pass the sanitized value directly
        } else if (value === '') {
            console.log("Input is empty, calling getDashboardData");
            await getDashboardData();  // Call getDashboardData when input is empty
        } else {
            console.log("Input length is not 8, no function called.");
        }
    };

    const handleSelectChange = async (e) => {
        const selectedValue = e.target.value;
        setsearchCriteria(selectedValue);
    
        if (searchQuery.length === 8) {
          await yourFunction(searchQuery, selectedValue);
        }
    };

    const yourFunction = async (input, criteria) => {
        console.log('8 digits entered!', input, 'Criteria:', criteria);
        await getDashboardData(input, criteria);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedYear) {
                await getDashboardAnalytics();
            }
        };
    
        fetchData();
    }, [selectedUserType,selectedYear]);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedYear) {
                await getDashboardData()
            }
        };
    
        fetchData();
    }, [selectedUserType,dashboardpagenumber,selectedYear,activeButton]);

    const handleShowMore = ()=>{
        setdashboardpagenumber(prev => prev + 1)
    }

    const handleShowless = ()=>{
        if( dashboardpagenumber !== 1 ){
            setdashboardpagenumber(prev => prev - 1)
        }
    }

    if (loading && dashboardpagenumber === 1 ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
    <div className='h-full w-full'>

        {/* <div className="grid grid-cols-5 max-xl:grid-cols-3 max-lg:grid-cols-1 max-xl:gap-4 max-xl:gap-y-0 gap-3">
            {
                cardsdata?.map((item, index) => (
                <div key={item.id} className="panel panel-success-alt noborder">
                    <div className={`panel-heading noborder ${item.background} h-full`}>
                    <div className="panel-icon text-black flex items-center justify-center ">
                        {item.Logo}
                    </div>
                    <div className="media-body">
                        <h6 className="uppercase text-[1.1rem] nomargin">{item.title}</h6>
                        <h2 className="mt5">{item.numbers}</h2>
                    </div>
                    </div>
                </div>
                ))
            }
        </div> */}
       
        {
            isActive && isMobile ? (
                <div className="grid grid-cols-5 max-xl:grid-cols-3 max-lg:grid-cols-1 max-xl:gap-4 max-xl:gap-y-0 gap-3">
                {
                    cardsdata?.map((item, index) => (
                    <div key={item.id} className="panel panel-success-alt noborder">
                        <div className={`panel-heading noborder ${item.background} h-full`}>
                        <div className="panel-icon text-black flex items-center justify-center ">
                            {item.Logo}
                        </div>
                        <div className="media-body">
                            <h6 className="uppercase text-[1.1rem] nomargin">{item.title}</h6>
                            <h2 className="mt5">{item.numbers}</h2>
                        </div>
                        </div>
                    </div>
                    ))
                }
                </div>
            ) : null
        }

        {
            !isMobile ? (
            <div className="grid grid-cols-5 max-xl:grid-cols-3 max-lg:grid-cols-1 max-xl:gap-4 max-xl:gap-y-0 gap-3">
                {
                    cardsdata?.map((item, index) => (
                    <div key={item.id} className="panel panel-success-alt noborder">
                        <div className={`panel-heading noborder ${item.background} h-full`}>
                        <div className="panel-icon text-black flex items-center justify-center ">
                            {item.Logo}
                        </div>
                        <div className="media-body">
                            <h6 className="uppercase text-[1.1rem] nomargin">{item.title}</h6>
                            <h2 className="mt5">{item.numbers}</h2>
                        </div>
                        </div>
                    </div>
                    ))
                }
            </div>
            ) : null
        }

        <div className='w-full p-8 bg-[#E4E7EA] flex gap-10 '>
            <input 
                type="text" 
                placeholder='Search Training' 
                className='w-[80%] py-1 px-4' 
                value={searchQuery}
                onChange={handleInputChange}
                maxLength={8}
                minLength={8}
                onPaste={handlePaste} 
            />
            <select
                onChange={handleSelectChange} 
                className='cursor-pointer py-3 h-full w-96 border-2'
            >  
                <option className='w-full' value="trainingcode">Training Code</option>
            </select>
        </div>

        <div className='w-full flex justify-end my-6'>
            <button
                style={{ backgroundColor: activeButton === 'alltrainings' ? theme?.palette?.primary?.main : "#ececec" }} 
                className={`py-4 px-8 max-sm:px-4 max-sm:py-2 font-medium max-sm:text-lg min-w-60 ${
                    activeButton === 'alltrainings' ? ' text-white rounded-l-lg' : ' text-black rounded-r-lg'
                    // activeButton === 'alltrainings' ? 'bg-[#337AB7] text-white rounded-l-lg' : 'bg-[#ececec] text-black rounded-l-lg'
                }`}
                onClick={() => handleToggle('alltrainings')}
            >
                All Trainings
            </button>
            <button
                style={{ backgroundColor: activeButton === 'myTrainings' ? theme?.palette?.primary?.main : "#ececec" }} 
                // style={{activeButton === 'myTrainings' ? theme?>}}
                className={`py-4 px-2 font-medium max-sm:text-lg w-52 ${
                // activeButton === 'myTrainings' ? 'bg-[#337AB7] text-white rounded-r-lg' : 'bg-[#ececec] text-black rounded-r-lg'
                activeButton === 'myTrainings' ? ' text-white rounded-r-lg' : ' text-black rounded-r-lg'
                }`}
                onClick={() => handleToggle('myTrainings')}
            >
                My Trainings
            </button>
        </div>
        
        <DashboardTrainings/>

        
        <div className="flex justify-center w-full gap-4  mt-4">
            {
                dashboardpagenumber > 1 && (
                <button
                    onClick={handleShowless}
                    style={{ backgroundColor: theme?.palette?.primary?.main }} 
                    className={`px-4 py-2 text-white rounded transition`}
                >
                    Previous
                </button>

                )
            }

            {
                dashboard_Data?.totalPages > dashboard_Data?.currentPage && (
                <button
                    onClick={handleShowMore}
                    style={{ backgroundColor: theme?.palette?.primary?.main }} 
                    className={`px-4 py-2 text-white rounded transition`}
                >
                    Next
                </button>
                )
            }
        </div>
    </div>
  )
}

export default TrainingsDashboard