import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useConfig } from '../../ConfigContext';
import { v4 as uuidv4 } from 'uuid';
import { Box, Tab, Tabs } from '@mui/material';

const UploadAssignment = ({ closeModal, participantId, uploaded }) => {
  const config = useConfig();
  const [selectedTab, setSelectedTab] = useState('Documents');
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [fileError, setFileError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [contentTypeID, setContentTypeID] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setFile(null);  // Reset file when switching tabs
    setFileError(null);  // Reset previous file error
  };

  // Fetch content type based on file extension
  const fetchContentTypeID = async (fileExtension) => {
    try {
      const response = await fetch(`${config?.REACT_APP_API_URL}/ContentType`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'APIKey': config.REACT_APP_API_KEY,
        },
      });
      const contentTypes = await response.json();

      const matchedType = contentTypes.find(type =>
        type.globalContentType.toLowerCase() === fileExtension.toLowerCase()
      );

      setContentTypeID(matchedType ? matchedType.globalContentTypeID : null);
    } catch (error) {
      console.error('Error fetching content types:', error);
      toast.error('Failed to fetch content types.');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file size (16 MB)
    if (selectedFile.size > 16 * 1024 * 1024) {
      setFileError('File size should not exceed 16 MB');
      setFile(null);
      return;
    }

    // Validate file type based on selected tab
    const acceptedFormats = selectedTab === 'Documents' ? ['pdf', 'ppt', 'pptx'] :
      selectedTab === 'Images' ? ['jpg', 'jpeg', 'png', 'gif'] :
        selectedTab === 'Videos' ? ['mp4', 'avi', 'mov', 'wmv'] : [];

    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    // Fetch the content type ID for the file extension
    fetchContentTypeID(fileExtension);

    // Check if the file's extension matches the allowed formats
    if (!acceptedFormats.includes(fileExtension)) {
      setFileError(`Invalid file type. Allowed formats: ${acceptedFormats.join(', ')}`);
      setFile(null);
      return;
    }

    setFile(selectedFile);
    setFileError(null);
  };

  const generateGUID = () => uuidv4();

  // Handle the file upload
  const uploadFile = async (draftStatus) => {
    if (!file) {
      toast.error('Please select a file!');
      return;
    }

    setUploading(true);

    try {
      const uniqueGUID = generateGUID();
      const fileFormData = new FormData();
      fileFormData.append('files', file, `${uniqueGUID}_${file.name}`);

      const uploadUrl = `${config?.LITTERA_CDN_BASE_URL}/Upload/UploadFile?Foldername=${config?.LITTERA_ASSIGNMENT_PATH}`;

      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: fileFormData,
      });

      if (!response.ok) {
        throw new Error(`File upload failed: ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 200) {
        const uploadedFileName = `${uniqueGUID}_${file.name}`;

        // After uploading the file, we call handleSubmit with the uploaded file name.
        handleSubmitTitleAndRemarks(uploadedFileName, draftStatus);
      } else {
        // Log the error but don't throw it
        console.error('File upload succeeded but returned an unexpected response:', data);
        toast.error('File uploaded with issues. Please check.');
      }
      
    } catch (error) {
    
    } finally {
      setUploading(false);
    }
  };


  const handleSubmitTitleAndRemarks = async (uploadedFileName, draftStatus) => {
    if (!title.trim()) {
      toast.error('Please enter a title.');
      return;
    }

    if (!remarks.trim()) {
      toast.error('Please enter remarks.');
      return;
    }

    const taau_uploadid = uploaded || generateGUID();
    const taau_createdon = new Date().toISOString();
    const participantid = user.userdetails.agencyid;

    const payload = {
      taau_uploadid,
      taau_uploadpath: uploadedFileName,  // Use the uploaded image name here
      taau_AssignmentID: participantId,
      taau_Participantid: participantid,
      taau_uploadedby: participantid,
      taau_title: title,
      taau_remark: remarks,
      taau_type: contentTypeID,
      taau_createdon,
      taau_status: draftStatus,  // Directly use draftStatus here
      taau_comment_json: [
        {
          taau_uploadid,
          taau_commentedby: participantid,
          taau_createdon,
          taau_comment: remarks,
        }
      ],
    };

    try {
      const response = await fetch(`${config?.REACT_APP_API_URL}/UploadAssignment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'APIKey': config.REACT_APP_API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit assignment: ${response.status}`);
      }

      const responseData = await response.json();

      if (draftStatus === 1) {
        toast.success('Draft updated successfully!');
      } else {
        toast.success('Assignment uploaded successfully!');
      }

      closeModal();
    } catch (error) {
      console.error('Error submitting assignment:', error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleSubmit = (draftStatus) => {
    uploadFile(draftStatus);
  };

  const getAllowedFileInfo = () => {
    const maxFileSizeMB = 16;
    if (selectedTab === 'Documents') {
      return `File Format Should Be In .PDF, .PPT, .PPTX. Max Size ${maxFileSizeMB} MB`;
    } else if (selectedTab === 'Images') {
      return `File Format Should Be In .JPG, .JPEG, .PNG, .GIF. Max Size ${maxFileSizeMB} MB`;
    } else if (selectedTab === 'Videos') {
      return `File Format Should Be In .MP4, .AVI, .MOV, .WMV. Max Size ${maxFileSizeMB} MB`;
    }
    return '';
  };

  return (
    <StyledModal visible={true} onCancel={closeModal} footer={[
      <Button key="cancel" onClick={closeModal}>Cancel</Button>,
      <Button key="draft" onClick={() => { handleSubmit(0); }} disabled={uploading}>
        Save Draft
      </Button>,
      <Button key="ok" type="primary" onClick={() => { handleSubmit(1); }} loading={uploading}>
        Save Final
      </Button>
    ]}>
      <div className="modal-header" style={{ display: 'flex', alignItems: 'center', borderBottom: 'none' }}>
        <h3>Upload Assignment</h3>
      </div>

      <div className="modal-box" style={{ border: "1px solid #d3c3c3", borderRadius: "5px", padding: "20px", paddingTop: "0px" }}>
        {/* MUI Tabs for Document, Image, Video */}
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={selectedTab} // Controls which tab is selected
            onChange={handleTabChange} // Triggered on tab change
            aria-label="Upload Tab"
            centered
            indicatorColor="primary"
          >
            <Tab label="Documents" value="Documents" sx={{ fontSize: '13px' }} />
            <Tab label="Images" value="Images" sx={{ fontSize: '13px' }} />
            <Tab label="Videos" value="Videos" sx={{ fontSize: '13px' }} />
          </Tabs>
        </Box>

        {/* Title Input */}
        <div style={{ marginBottom: 5, marginTop: "20px" }}>
          <Input
            placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>

        {/* Choose File Button */}
        <div style={{ marginBottom: 5 }}>
          <Button onClick={() => document.getElementById('fileInput').click()} style={{ marginRight: '10px', width: '100%', backgroundColor: "#70707e" }}>
            Choose File
          </Button>
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept={selectedTab === 'Documents' ? '.pdf,.ppt,.pptx' :
              selectedTab === 'Images' ? '.jpg,.jpeg,.png,.gif' :
                selectedTab === 'Videos' ? '.mp4,.avi,.mov,.wmv' : ''}
          />
        </div>

        {/* File Preview */}
        {file && (
          <div style={{ marginTop: 20 }}>
            {file.type.startsWith('image') && (
              <div>
                <img src={URL.createObjectURL(file)} alt="File Preview" style={{ width: '100%', maxWidth: '400px' }} />
              </div>
            )}
            {file.type.startsWith('video') && (
              <video controls width="100%" style={{ maxWidth: '400px' }}>
                <source src={URL.createObjectURL(file)} type={file.type} />
                Your browser does not support the video tag.
              </video>
            )}
            {!file.type.startsWith('image') && !file.type.startsWith('video') && (
              <div>{file.name}</div>
            )}
          </div>
        )}

        {/* Allowed File Info */}
        <div style={{ marginTop: 10, color: '#888' }}>
          {getAllowedFileInfo()}
        </div>

        {/* Error Message */}
        {fileError && <div style={{ color: 'red', marginTop: 10 }}>{fileError}</div>}

        {/* Remarks Input */}
        <div style={{ marginTop: 20 }}>
          <Input.TextArea
            placeholder="Enter Remarks (Optional)"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            rows={4}
          />
        </div>
      </div>
    </StyledModal>
  );
};

export default UploadAssignment;

const StyledModal = styled(Modal)`
  .ant-modal {
    width: 55% !important;
    top: 10px !important;
  }
  .ant-modal-body {
    padding: 20px;
  }
  .ant-input {
    margin-bottom: 10px;
  }
  .ant-input-textarea {
    margin-bottom: 20px;
  }
  .ant-btn-primary {
    background-color: #70707e;
  }
`;
