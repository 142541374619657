import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useFeedback } from '../Context/FeedbackContext.jsx';
import Breadcrumb from '../componentHome/commonComponent/Breadcrumbs.js';
import SessionDetailModal from '../components/Content/Session/SessionDetailModal.jsx';
import SessionList from '../components/Content/Session/SessionList.jsx';
import { useParams } from 'react-router-dom';
const SessionDetails = () => {

    
    const paths = [
        { title: 'Home', url: '/' },
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Session Details', url: '/Session_trg/SessionDetail?trainingid' }
    ];
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <Breadcrumb paths={paths} />
        <div className='p-6 h-full w-full'>
            <SessionDetailModal pagetype={'session'}/>
            
            <SessionList  />

        </div>
    </>
}

export default SessionDetails