// import React from 'react';
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const Progressbar = ({ completionPercentage }) => {
//   return (
//     <ProgressBar 
//       className='shadow-md' 
//       striped 
//       animated 
//       // label={`${completionPercentage}%`} 
//       label={<span className="progress-bar-label">{completionPercentage}%</span>}       
//       now={completionPercentage} 
//     />
//   );
// };

// export default Progressbar;

import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Progressbar = ({ completionPercentage , traininigid  }) => {
  
  const navigate = useNavigate()
  // Dynamic style for the position of the percentage label
  const adjustedPercentage = completionPercentage > 10 ? completionPercentage - 5 : completionPercentage;

  // Dynamic style for the position of the percentage label
  const labelStyle = {
    position: 'absolute',
    top: '50%',
    left: `${completionPercentage > 60 ? '50%' : `${adjustedPercentage}%`}`, // Use adjusted percentage
    transform: 'translateY(-50%)',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    transformOrigin: 'center',
    transition: 'left 0.2s ease', // Smooth transition for label movement
    color: 'white', // You can adjust the color to make it more readable
    paddingLeft: '4px', // Add padding from the left
    paddingBottom: '2px',
  };

  const navigateTrainingProgressReport = ()=>{
    navigate(`/training-progress-report/${traininigid}`)
  }

  return (
    <div style={{ position: 'relative' }}>
      <ProgressBar
        className="shadow-md mb-0 cursor-pointer"
        striped
        animated
        now={completionPercentage}
        onClick={navigateTrainingProgressReport}
        // label={`${completionPercentage}%`} // Shows label inside the progress bar
      />
      <span onClick={navigateTrainingProgressReport} className="sr-only">{`${completionPercentage}%`}</span>
      <span onClick={navigateTrainingProgressReport} style={labelStyle}>{`${completionPercentage}%`}</span>
    </div>
  );
};

export default Progressbar;
