import { Box, Button,Typography,CircularProgress } from '@mui/material'
import React,{useEffect,useState} from 'react'
import axios from 'axios'
import { useConfig } from '../../ConfigContext'
const TestSubmit = () => {
    const config= useConfig()
    console.log("config",config)
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)
    const user = JSON.parse(localStorage.getItem('user'));
    const branchid = user.userdetails.branchid;
  console.log("user", user);
  useEffect(() => {
    const fetchData = async () => {
        try {
            if (!config?.REACT_APP_API_URL || !branchid) {
                console.error('Missing config or branchid');
                return;
            }

            const url = `${config.REACT_APP_API_URL}/agency?agencytype=00001&agencyid=${branchid}`;
            console.log('Fetching data from URL:', url);

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    APIKey: config.REACT_APP_API_KEY,
                },
            });

            // Check if the pagedata exists and has the data you expect
            if (response.data?.items?.length > 0) {
                setData(response.data.items[0]);
                console.log('Fetched data:', response.data.items[0]);
            } else {
                console.error('No pagedata found in the response', response.data);
                setError('No data available');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [branchid, config]); // Adding dependencies so that the effect reruns if branchid or config changes

    if (loading) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f9f9f9",
            }}>
                <CircularProgress />
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundColor: "#f9f9f9",

        }}>
            <Box sx={{ textAlign: "center", mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                     src={`${config?.LITTERA_CDN_BASE_URL}/${data?.ag_photo_path}`}
                    alt='logo'
                    style={{ width: '66px' }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '2rem', // Adjust the font size as needed
                    }}
                >
                    {data?.HAgencyName}
                </Typography>
                <Typography variant="body2" style={{ fontSize: 'medium'}}>
                {data?.Ag_Address}
                </Typography>
            </Box>
            <Box sx={{
                width: "88%",
                backgroundColor: "#d9edf7",
                p: 4,
                borderRadius: 2,
                boxShadow: 2,
                textAlign: 'center',
            }}>
                <Typography variant="h6" color="#31708f" style={{ fontSize:"medium"}}> Thank you for submitting your answers</Typography>

            </Box>

        </Box>
    )
}

export default TestSubmit