import React, { useContext, useEffect, useRef, useState } from 'react'
import { useConfig } from '../../../ConfigContext'
import axios from 'axios';
import { useFeedback } from '../../../Context/FeedbackContext';
import { useTheme } from '../../../ThemeProvider';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { IoFlask, IoFolder, IoRocketSharp, IoTrash } from 'react-icons/io5';
import { FaBook , FaEdit, FaHeadphones, FaPrint, FaRegPlayCircle, FaSuitcase, FaUser } from 'react-icons/fa';
import { RiFileExcel2Fill, RiProgress1Line, RiWhatsappFill } from 'react-icons/ri';
import { MdScreenShare, MdFeedback } from 'react-icons/md';
import 'bootstrap/dist/css/bootstrap.min.css';
import { List, ListItem, ListItemText, Collapse, ListItemSecondaryAction, IconButton, ListItemAvatar, Avatar, Box, CircularProgress, Typography, useMediaQuery, Tooltip} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CreateSessionFromCalendar from '../Calender/CreateSessionFromCalendar';
import TwoMonthCalendar from './TwoMonthCalendar';
import { useUser } from '../../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LuActivity } from 'react-icons/lu';
import { FaCirclePlay, FaRegCircleCheck } from 'react-icons/fa6';
import { TranslationContext } from '../../../TranslationContext';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

const HTMLParser = ({ iconname }) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
      <span dangerouslySetInnerHTML={createMarkup(iconname)} />
  );
};

const CircularProgressBar = ({ session }) => {
  const { theme } = useTheme()
  
  return (
    <div 
      style={{ 
        position: 'relative', 
        backgroundColor: 'transparent', // Transparent background
        borderRadius: '50%', // Circular shape
        width: 40, // Adjusted size
        height: 40, // Adjusted size
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px' // Removed padding
      }}
    >
      {/* Empty Bar */}
      <CircularProgress
        variant="determinate"
        value={100} // Always full for the empty bar
        size={40} // Match the container size
        thickness={4}
        style={{ 
          position: 'absolute', 
          color: theme?.palette?.primary?.main === '#d32f2f' ? '#ffb3b3' : '#b3d7ff', // Light blue for the empty bar
        }} 
      />
      {/* Filled Bar */}
      <CircularProgress
        variant="determinate"
        value={session} // Progress percentage
        size={40} // Match the container size
        thickness={4}
        style={{ 
          position: 'absolute', 
          color: theme?.palette?.primary?.main , 
        }} 
      />
      {/* Percentage Text */}
      <Typography 
        variant="body2" 
        style={{ 
          position: 'absolute', 
          left: '50%', 
          top: '50%', 
          transform: 'translate(-50%, -50%)', 
          // color: '#007bff' // Same color as filled bar
          color: theme?.palette?.primary?.main // Same color as filled bar
        }}
      > 
      {
        session === 0 ? 
          `${session}%` : 
          (Number.isInteger(session) ? `${session}%` : `${session.toFixed(1)}%`)
      }
      </Typography>
    </div>
  );
};

const NestedList = () => {
  const config = useConfig()
  const { id } = useParams()
  const { theme } = useTheme()
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading , setLoading] = useState(null)
  const { selectedUserType } = useUser()
  const { 
    sessions, 
    setsessions , 
    uncheckedItems , 
    excludedFacultyIds , 
    noFacultySessionIds, 
    allWithoutFacultySelected, 
    trainingDetails,
    startDate,
    endDate,
    sessionid, 
    setsessionid,
    initialAllowedStartDate,
    initialAllowedEndDate
  } = useFeedback()
  const { translations } = useContext( TranslationContext )
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getEvalIds = async () => {

      const headers = {
        Accept: 'application/json, text/plain',
        'API-Key': config?.REACT_APP_EVAL_API_KEY,
        'Content-Type': 'application/json',
      };

      try {
        const { data } = await axios.get(`${config?.REACT_APP_EVAL_API_PATH}/Tests/UserTests`,
          {
            headers,
            params: {
              usertype:selectedUserType ,
              userid: user?.userdetails?.agencyid,
              trainingid:id
            }
          });
        return data;
      } catch (error) {
        console.log('Error fetching sessions', error);
      }
  };

  const navigattestresult = async() => {
    const data = await getEvalIds()
    navigate(`/test-result` , { 
      state: { 
        testparticipantid:data[0]?.participantstatus , 
        testquestionid:data[0]?.testQuestionID , 
      } 
    });
  };
  
  const openLitteraRoom = (session) => {  
    console.log('session' , session)
    console.log('trainingDetails' , trainingDetails?.trainingId)
    navigate(`/Litteraroom/session/${trainingDetails?.trainingId}`);
    setsessionid(session)
  };

  const navigatSelftest = (session) => {  
    console.log('navigatedata' , session)
    navigate('/selftest-page', { 
      state: { 
        keyword:session?.ttttt_tag , 
        trainingid:session?.trainingid , 
        sessionid:session?.ttttt_session_id , 
        trainingcategoryid:trainingDetails?.trainingCategoryId  
      } 
    });
  };

  const getSessions = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TrgSessions?trainingid=${id}`,
          {
            headers,
            params: {
              usertype:selectedUserType ,
              userid: user?.userdetails?.agencyid
            }
          });
        return data;
      } catch (error) {
        console.log('Error fetching sessions', error);
      }
    } else {
      console.log('No id provided');
    }
  };

  function formatDate(datetimeString) {
    // Extract the date part (first 10 characters)
    let dateString = datetimeString.substring(0, 10);

    // Split the date into year, month, and day
    let parts = dateString.split("-");
    let year = parts[0]; // Full year
    let month = parts[1];
    let day = parts[2];

    // Form the desired date format "dd-mm-yyyy"
    let formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const getDisplayString = (session) => {
    const displayParts = [];
    const displayOrderKeys = session?.displayOrder?.split(',') || [];
    const displayInfos = session?.displayInfos || [];
  
    const displayInfoMap = {};
    displayInfos.forEach(info => {
      displayInfoMap[info.key] = info; 
    });
  
    displayOrderKeys.forEach((key) => {
      const displayInfo = displayInfoMap[key];
  
      if (displayInfo?.value) { 
        switch (key) {
          case '1':
            displayParts.push(`${translations["Module"] || "Module"} - ${session?.module || ''}`);
            break;
          case '2':
            displayParts.push(`${translations["Week"] || "Week"} - ${session?.ttttt_session_week || ''}`);
            break;
          case '3':
            displayParts.push(`📅 ${formatDate(session.ttttt_session_dt) || ''} 🕒 ${session.ttttt_session_time || ''}`);
            break;
          case '4':
            displayParts.push(`${translations["Days"] || "Days"} - ${session?.ttttt_session_day || ''}`);
            break;
          case '5':
            displayParts.push(`📝 ${translations["Srno"] || "SR"}  #${session?.ttttt_session_no || ''}`);
            break;
          default:
            break;
        }
      }
    });
  
    return displayParts.join(' ').trim();
  };

  const normalizeDate = (date) => new Date(date?.setHours(0, 0, 0, 0));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getSessions();
        setsessions(data)
      } catch (error) {
        console.error('Error fetching sessions:', error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [selectedUserType]);
  
  const [openTooltipIndex, setOpenTooltipIndex] = useState(null);

  const handleTooltipToggle = (index) => {
    if (isMobile) {
      setOpenTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
    }
  };

  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpenTooltipIndex(null); // Close the tooltip
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 

  const disabletooltip = ()=>{
    if(openTooltipIndex != null){
      setOpenTooltipIndex(null)
    }
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
      </div>
    );
  }

  return (
    <div className='min-h-[35vh]'>
      <List className='flex flex-col gap-4' component="nav">
          {
            sessions
            ?.filter(session => {
              const isUncheckedItem = uncheckedItems?.some(item => item?.id == session?.ttttt_type);
              const hasExcludedFaculty = session?.sessionFaculties?.some(faculty => excludedFacultyIds?.includes(faculty?.facultyid));

              // Check if startDate and endDate are null
              if (startDate == null && endDate == null) {
                return false; // Hide all sessions
              }

              const sessionStartDate = new Date(session?.ttttt_session_dt);
              const sessionEndDate = new Date(session?.ttttt_session_end_time);

              // Normalize dates for comparison
              const normalizedStartDate = normalizeDate(startDate).getTime();
              const normalizedEndDate = normalizeDate(endDate).getTime();
              const normalizedInitialStartDate = normalizeDate(initialAllowedStartDate).getTime();
              const normalizedInitialEndDate = normalizeDate(initialAllowedEndDate).getTime();

              // Check if both startDate and endDate are the same as the allowed dates
              const isExactAllowedDateRange = normalizedStartDate === normalizedInitialStartDate &&
                                              normalizedEndDate === normalizedInitialEndDate;

              // Check if session is within the date range unless it's the exact allowed range
              const isInDateRange = isExactAllowedDateRange || 
                                    (sessionStartDate > startDate && sessionEndDate <= endDate) ||
                                    (startDate?.getTime() === endDate?.getTime() && 
                                    sessionStartDate >= startDate && 
                                    sessionEndDate >= startDate);
              return (
                !isUncheckedItem && 
                !hasExcludedFaculty && 
                (allWithoutFacultySelected || session?.sessionFaculties?.length > 0) &&
                isInDateRange
              );
            })
            .filter((ses, index, self) => 
              self.findIndex(s => s.ttttt_session_id === ses.ttttt_session_id) === index
            ) 
            .map((session, index) => (
            <div key={index}>
              <ListItem className='bg-[#e5e5e5] h-fit rounded-lg' button onClick={() => handleClick(index)}>
                <Box display="flex" flexDirection="column" sx={{ width: '100%'  }}>
                  <ListItemText
                    onClick={disabletooltip}
                    primaryTypographyProps={{ sx: { fontSize: '1.4rem', lineHeight: isMobile ? '2' : null } }}
                    secondaryTypographyProps={{ sx: { fontSize: '1.2rem' } }}
                    // primary={`${getDisplayString(session)} ⏱️ ${session.ttttt_session_duration} ${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`}
                    primary={`${getDisplayString(session)} ⏱️ ${session.ttttt_session_duration == 0 ? '' : `${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`}`}
                    secondary={
                      <span 
                      title={!isMobile ? `${session.ttttt_content_desc} - ${session.ttttt_subject}` : null}
                      className="flex capitalize items-center">
                        <Tooltip
                          title={isMobile ? `${session.ttttt_content_desc} - ${session.ttttt_subject}` : null}
                          open={isMobile ? openTooltipIndex === index : undefined} 
                          disableInteractive
                        >
                          <span
                            onClick={() => handleTooltipToggle(index)} 
                            className=''
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              whiteSpace: 'normal',
                              cursor: 'pointer', 
                            }}
                          >
                            {
                              (() => {
                                const combinedText = `${session.ttttt_content_desc} - ${session.ttttt_subject}`;
                                const maxWords = 25;  
                                const words = combinedText.split(' ');

                                const truncatedHtml = words.length > maxWords
                                  ? words.slice(0, maxWords).join(' ') + '...'
                                  : combinedText;

                                  return <span dangerouslySetInnerHTML={{ __html: truncatedHtml }} />;
                                  
                              })()
                            }
                          </span>
                        </Tooltip>
                        <IconButton  edge="end" aria-label="expand">
                          {openTooltipIndex === index ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </span>
                    }
                  />

                  {isMobile && (
                    <Typography 
                      onClick={disabletooltip}
                      variant="body2" color="textSecondary" 
                      className="flex-wrap"
                      sx={{ display: 'flex' ,alignItems: 'center', gap: "6px" }}
                    >
                      {
                        session?.actionInfos?.filter(action => action.value && !["EDIT_SESSION", "DELETE_SESSION", "CONTENT_LIBRARY" ,"Complete_Session"].includes(action.name)) // Filter for actions where value is true and not in the exclusion list
                          .map(action => (
                            <IconButton
                              key={action.key}
                              // className='flex uppercase items-center text-[1.1rem] font-semibold gap-2'
                              className='flex uppercase  items-start text-[1.1rem] font-semibold gap-2'
                              edge="end"
                              aria-label={action.name.toLowerCase().replace('_', ' ')}
                              // onClick={action.name === "LITTERA_ROOM" ? openLitteraRoom : null }                    
                              onClick={
                                action.name === "LITTERA_ROOM" ? () => openLitteraRoom(session) 
                                : 
                                action.name === "View_TEST_RESULT" ? navigattestresult 
                                : 
                                action.name === "CHECK_COMPETENCY" ? () => navigatSelftest(session)  : null 
                              }                    
                            >
                              {
                                action.name === "CREATE_MEETING" || action.name === "START_MEETING" ?
                                <FaCirclePlay fontSize={14} color='red'/>
                                :
                                action.name === "JOIN_MEETING" ?
                                <FaHeadphones fontSize={14} color='#8fbc8f'/>
                                :
                                action.name === "SHARE_MEETING" ?
                                <RiWhatsappFill fontSize={14} color='#25D366'/>
                                :
                                action.name === "LITTERA_ROOM" ?
                                <IoRocketSharp />
                                :
                                action.name === "SHARE_FEEDBACK" ?
                                <MdScreenShare />
                                :
                                action.name === "CHECK_COMPETENCY" ?
                                <FaRegCircleCheck />
                                :
                                action.name === "GIVE_FEEDBACK" ?
                                <MdFeedback />
                                :
                                action.name === "RUN_TEST" ?
                                <FaRegPlayCircle />
                                :
                                action.name === "View_TEST_RESULT" ?
                                <i style={{ color:"red" }} data-title="View_test_Result" class="glyphicon glyphicon-dashboard" title="View Test Result"></i>
                                :
                                action.name === "Complete_Session" ?
                                <RiProgress1Line />
                                :
                                null
                              }
                              {
                                action.name === 'CREATE_MEETING' ? (
                                  translations["Create_Meeting_button"] || "CREATE MEETING"
                                ) : 
                                action.name === 'START_MEETING' ? (
                                  translations["Start_meeting"] || "START MEETING"
                                ) : 
                                action.name === 'JOIN_MEETING' ? (
                                  translations["Join_meeting"] || "JOIN MEETING"
                                ) : 
                                action.name === 'SHARE_MEETING' ? (
                                  `${translations["Share"]} ${translations["Meeting"]}` || "SHARE MEETING"
                                ) : 
                                action.name === 'Littera_room' ? (
                                  translations["Littera_room"] || "LITTERA ROOM"
                                ) : 
                                action.name === 'SHARE_FEEDBACK' ? (
                                  translations["Share_survey"] || "SHARE FEEDBACK"
                                ) : 
                                action.name === 'CHECK_COMPETENCY' ? (
                                  translations["Self_assessment"] || "Self Test"
                                ) : 
                                // action.name === 'GIVE_FEEDBACK' ? (
                                  //   translations["GIVE_FEEDBACK"] || "GIVE FEEDBACK"
                                  // ) : 
                                action.name === 'SHARE_MEETING' ? (
                                  `${translations["Share"]} ${translations["Meeting"]}` || "SHARE MEETING"
                                ) : 
                                action.name === 'RUN_TEST' ? (
                                  'Start Test'
                                ) : (
                                action.name === 'View_TEST_RESULT' ? (
                                  translations["View_test_Result"] || "View test Result"
                                ) : 
                                action.name.replace('_', ' ')
                                )
                              }
                            </IconButton>
                      ))}

                      <IconButton 
                            className='flex uppercase items-center text-[1.1rem] font-semibold gap-2'
                            edge="end" aria-label="edit" 
                          >
                            <HTMLParser iconname={session?.session_type_icon} />
                              { 
                                session?.session_type_name === 'Academic' ? (
                                  translations["Personled"] || "Person led"
                                ) :
                                session?.session_type_name === 'Break' ? (
                                  translations["Breaks"] || "Break"
                                ) :
                                session?.session_type_name === 'Tour' ? (
                                  translations["Tour  "] || "Tour"
                                ) :
                                session?.session_type_name === 'GroupDiscussion' ? (
                                  translations["Group_discussion"] || "Group Disscussion"
                                ) :
                                session?.session_type_name === 'Assignment' ? (
                                  translations["Assignment"] || "Assignment"
                                ) :
                                session?.session_type_name === 'PT' ? (
                                  translations["PT"] || "PT"
                                ) :
                                session?.session_type_name === 'Sports' ? (
                                  translations["Sports"] || "Sport"
                                ) :
                                session?.session_type_name === 'Practical' ? (
                                  translations["Practical"] || "Practical"
                                ) :
                                session?.session_type_name === 'SelfPaced' ? (
                                  translations["Self_paced"] || "Self paced"
                                ) :
                                session?.session_type_name
                              }

                      </IconButton>

                      <IconButton 
                            className='flex items-center text-[1.1rem] font-semibold gap-2'
                            edge="end" 
                            aria-label="edit"
                            disabled={session?.completionpercentage === undefined} 
                          >
                          <CircularProgressBar session={session?.completionpercentage}/>
                      </IconButton>
                    </Typography>
                  )}
                </Box>
                
                {!isMobile && (
                  <ListItemSecondaryAction 
                    className="flex-wrap"
                    sx={{ display: 'flex', alignItems: 'center', gap: "6px" }}
                  >
                    {
                      session?.actionInfos?.filter(action => action.value && !["EDIT_SESSION", "DELETE_SESSION", "CONTENT_LIBRARY" ,"Complete_Session"].includes(action.name)) // Filter for actions where value is true and not in the exclusion list
                        .map(action => (
                        <IconButton
                          key={action.key}
                          className='flex uppercase items-center text-[1.1rem] font-semibold gap-2'
                          edge="end"
                          aria-label={action.name.toLowerCase().replace('_', ' ')}
                          // onClick={action.name === "LITTERA_ROOM" ? openLitteraRoom : null }                    
                          onClick={
                            action.name === "LITTERA_ROOM" ? () => openLitteraRoom(session) 
                            : 
                            action.name === "View_TEST_RESULT" ? navigattestresult 
                            : 
                            action.name === "CHECK_COMPETENCY" ? navigatSelftest  : null 
                          }                    
                        >
                          {
                            action.name === "CREATE_MEETING" || action.name === "START_MEETING" ?
                            <FaCirclePlay fontSize={14} color='red'/>
                            :
                            action.name === "JOIN_MEETING" ?
                            <FaHeadphones fontSize={14} color='#8fbc8f'/>
                            :
                            action.name === "SHARE_MEETING" ?
                            <RiWhatsappFill fontSize={14} color='#25D366'/>
                            :
                            action.name === "LITTERA_ROOM" ?
                            <IoRocketSharp />
                            :
                            action.name === "SHARE_FEEDBACK" ?
                            <MdScreenShare />
                            :
                            action.name === "CHECK_COMPETENCY" ?
                            <FaRegCircleCheck />
                            :
                            action.name === "GIVE_FEEDBACK" ?
                            <MdFeedback />
                            :
                            action.name === "RUN_TEST" ?
                            <FaRegPlayCircle />
                            :
                            action.name === "View_TEST_RESULT" ?
                            <i style={{ color:"red" }} data-title="View_test_Result" class="glyphicon glyphicon-dashboard" title="View Test Result"></i>
                            :
                            action.name === "Complete_Session" ?
                            <RiProgress1Line />
                            :
                            null
                          }
                          {
                            action.name === 'CREATE_MEETING' ? (
                              translations["Create_Meeting_button"] || "CREATE MEETING"
                            ) : 
                            action.name === 'START_MEETING' ? (
                              translations["Start_meeting"] || "START MEETING"
                            ) : 
                            action.name === 'JOIN_MEETING' ? (
                              translations["Join_meeting"] || "JOIN MEETING"
                            ) : 
                            action.name === 'SHARE_MEETING' ? (
                              `${translations["Share"]} ${translations["Meeting"]}` || "SHARE MEETING"
                            ) : 
                            action.name === 'Littera_room' ? (
                              translations["Littera_room"] || "LITTERA ROOM"
                            ) : 
                            action.name === 'SHARE_FEEDBACK' ? (
                              translations["Share_survey"] || "SHARE FEEDBACK"
                            ) : 
                            action.name === 'CHECK_COMPETENCY' ? (
                              translations["Self_assessment"] || "Self Test"
                            ) : 
                            // action.name === 'GIVE_FEEDBACK' ? (
                              //   translations["GIVE_FEEDBACK"] || "GIVE FEEDBACK"
                              // ) : 
                            action.name === 'SHARE_MEETING' ? (
                              `${translations["Share"]} ${translations["Meeting"]}` || "SHARE MEETING"
                            ) : 
                            action.name === 'RUN_TEST' ? (
                              'Start Test'
                            ) : (
                            action.name === 'View_TEST_RESULT' ? (
                              translations["View_test_Result"] || "View test Result"
                            ) : 
                            action.name.replace('_', ' ')
                            )
                          }
                        </IconButton>
                      ))}
                
                      <IconButton 
                                    className='flex uppercase items-center text-[1.1rem] font-semibold gap-2'
                                    edge="end" aria-label="edit" 
                                  >
                                    <HTMLParser iconname={session?.session_type_icon} />
                                      { 
                                        session?.session_type_name === 'Academic' ? (
                                          translations["Personled"] || "Person led"
                                        ) :
                                        session?.session_type_name === 'Break' ? (
                                          translations["Breaks"] || "Break"
                                        ) :
                                        session?.session_type_name === 'Tour' ? (
                                          translations["Tour  "] || "Tour"
                                        ) :
                                        session?.session_type_name === 'GroupDiscussion' ? (
                                          translations["Group_discussion"] || "Group Disscussion"
                                        ) :
                                        session?.session_type_name === 'Assignment' ? (
                                          translations["Assignment"] || "Assignment"
                                        ) :
                                        session?.session_type_name === 'PT' ? (
                                          translations["PT"] || "PT"
                                        ) :
                                        session?.session_type_name === 'Sports' ? (
                                          translations["Sports"] || "Sport"
                                        ) :
                                        session?.session_type_name === 'Practical' ? (
                                          translations["Practical"] || "Practical"
                                        ) :
                                        session?.session_type_name === 'SelfPaced' ? (
                                          translations["Self_paced"] || "Self paced"
                                        ) :
                                        session?.session_type_name
                                      }
                                      
                
                      </IconButton>
                
                      <IconButton 
                                    className='flex items-center text-[1.1rem] font-semibold gap-2'
                                    edge="end" 
                                    aria-label="edit"
                                    disabled={session?.completionpercentage === undefined} 
                                  >
                                  <CircularProgressBar session={session?.completionpercentage}/>
                      </IconButton>
                
                  </ListItemSecondaryAction>
                )}
              </ListItem>

              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem className='flex flex-col items-start' button>
                    <Box className='max-phone:flex-col max-phone:gap-4' sx={{ display: 'flex' ,alignItems: 'center' , mb: 1}}>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {session?.sessionFaculties.length > 0 ? (
                          session.sessionFaculties.map((faculty, index) => (
                            <ListItemAvatar 
                              key={index}
                              title={faculty.facultyname}
                            >
                              <Avatar
                                alt={faculty.facultyname}
                                src={
                                  faculty.facultuimg
                                    ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${faculty.facultuimg}`
                                    : `${config?.LITTERA_CDN_BASE_URL}/theme/images/faculty.jpg`
                                }
                                onError={(e) => {
                                  e.target.onerror = null; // Prevents looping
                                  e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/faculty.jpg`; // Fallback image URL
                                }}
                                sx={{
                                  border: `2px solid ${theme?.palette?.primary?.main}`,
                                  marginRight: '8px', // Spacing between images
                                }}
                              />
                            </ListItemAvatar>
                          ))
                        ) : (
                          null
                        )}
                      </Box>

                      {session?.sessionFaculties.length > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {session.sessionFaculties.map((faculty, index) => (
                            <React.Fragment key={index}>
                              <span 
                                title={faculty.facultyname}
                              >
                                {faculty.facultyname}
                              </span>
                              {index < session.sessionFaculties.length - 1 && ', '}
                            </React.Fragment>
                          ))}
                        </Box>
                      )}

                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, gap: '14px', mt: 1 }}>
                      {session?.actionInfos
                        .filter(action => action.value && ["EDIT_SESSION", "DELETE_SESSION", "CONTENT_LIBRARY"].includes(action.name))
                        .map(action => {
                          let iconButton = null;
                          if (action.name === "EDIT_SESSION") {
                            iconButton = (
                              <IconButton title='Edit Session Details' className='p-0' >
                                <FaEdit />
                              </IconButton>
                            );
                          } else if (action.name === "DELETE_SESSION") {
                            iconButton = (
                              <IconButton title='Delete Session' className='p-0' >
                                <IoTrash />
                              </IconButton>
                            );
                          } else if (action.name === "CONTENT_LIBRARY") {
                            iconButton = (
                              <IconButton title='Content Library' className='p-0' >
                                <IoFolder color='#FFA500' />
                              </IconButton>
                            );
                          }
                          return iconButton;
                        })}
                    </Box>
                  </ListItem>
                </List>
              </Collapse>
            </div>
          ))
        }
      </List>
    </div>
  );
};


const SessionList = () => {

    const { translations } = useContext(TranslationContext);
    const config = useConfig()
    const { trainingDetails } = useFeedback()
    const { 
        sessions , 
        sessionType , 
        setsessionType , 
        setLoading , 
        showModal, 
        setShowModal , 
        sessionDates,
        startDate, 
        endDate, 
        initialAllowedStartDate, 
        initialAllowedEndDate, 
        setStartDate,
        setEndDate,
        setInitialAllowedStartDate,
        setInitialAllowedEndDate,
        uncheckedItems, 
        setUncheckedItems,
        excludedFacultyIds, 
        setExcludedFacultyIds,
        noFacultySessionIds, 
        setNoFacultySessionIds,
        allWithoutFacultySelected, 
        setAllWithoutFacultySelected
    } = useFeedback()
    const { loading } = useFeedback()
    const [createbutton, setcreatebutton] = useState(null);
    const [contentlibrarybutton, setcontentlibrarybutton] = useState(null);
    const [durationtype, setdurationtype] = useState(null);
    const { selectedUserType, setSelectedUserType } = useUser()
    const { theme } = useTheme()
    const { id } = useParams()
    const user = JSON.parse(localStorage.getItem('user'));
    const [allSessionChecked, setAllSessionChecked] = useState(true);
    const [selectedFaculties, setSelectedFaculties] = useState(new Set());
    
    const getSessionType = async () => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SessionType`, {
            headers: headers,
          });
          setsessionType(data);
        } catch (error) {
          console.log('Error session type', error);
        }
    };

    const getTrgSessionDuration = async () => {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
  
      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TRG_SESSIONS_DURATION`, {
          headers: headers,
          params:{
            trainingid:id,
            durationtype:2
          }
        });
        setdurationtype(data);

      } catch (error) {
        console.log('Error session type', error);
      }
    };

    useEffect(() => {
      const fetchdata = async () =>{
          await getSessionType()
          await getTrgSessionDuration()
      }
      fetchdata()
    }, [])

    const getCheckCreateButtonPermission = async () => {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
  
      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CheckPermission`, {
          headers: headers,
          params:{
            userid:user?.userdetails?.agencyid,
            usertype:selectedUserType,
            chkpermission:2,
            formid: '524,8928'
          }
        });
        return data
        
      } catch (error) {
        console.log('Error session type', error);
      }
    };

    const getCheckContentLibraryButtonPermission = async () => {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
  
      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CheckPermission`, {
          headers: headers,
          params:{
            userid:user?.userdetails?.agencyid,
            usertype:selectedUserType,
            chkpermission:1,
            formid: '9623'
          }
        });
        return data
      } catch (error) {
        console.log('Error session type', error);
      }
    };

    useEffect(() => {
      Promise.all([
          getCheckCreateButtonPermission(),
          getCheckContentLibraryButtonPermission()
      ])
      .then(([CreateButton, ContentLibrary]) => {
          setcreatebutton(CreateButton);
          setcontentlibrarybutton(ContentLibrary);
      })
      .catch(error => {
          console.error('Error fetching sessions:', error);
      });
    }, [selectedUserType]);
  
    useEffect(() => {
      if (allSessionChecked) {
        const allFacultyIds = new Set(
          sessions?.flatMap(session => 
            session.sessionFaculties?.map(faculty => faculty.facultyid) || []
          )
        );
        setSelectedFaculties(allFacultyIds); // Select all faculties initially
      }
    }, [allSessionChecked, sessions]);
    
    const normalizeDate = (date) => new Date(date?.setHours(0, 0, 0, 0));

    const fillAllDates = () => {
      const initialStartDate = normalizeDate(initialAllowedStartDate).getTime();
      const initialEndDate = normalizeDate(initialAllowedEndDate).getTime();
      const currentStartDate = normalizeDate(startDate).getTime();
      const currentEndDate = normalizeDate(endDate).getTime();
    
      const isStartDateInitial = initialStartDate === currentStartDate;
      const isEndDateInitial = initialEndDate === currentEndDate;
    
      if (!isStartDateInitial && !isEndDateInitial) {
        setStartDate(initialAllowedStartDate);
        setEndDate(initialAllowedEndDate);
      } else {
        if (isStartDateInitial && isEndDateInitial) {
          setStartDate(null);
          setEndDate(null);
        } else {
          setStartDate(initialAllowedStartDate);
          setEndDate(initialAllowedEndDate);
        }
      }
    };
    
// __________________________ Type filter __________________________________
    
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
      if (sessionType) {
        setSelectedItems(Array(sessionType?.length).fill(true));
        setUncheckedItems([]); // Clear unchecked items when sessionType is set
      }
    }, [sessionType]);

    useEffect(() => {
      if (sessionType && selectedItems?.length > 0) {
        const currentUncheckedItems = sessionType?.filter((_, index) => !selectedItems[index]);
        setUncheckedItems(currentUncheckedItems);
        setSelectAll(selectedItems.every(item => item));
      }
    }, [selectedItems, sessionType]);

    const handleSelectAll = () => {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
      setSelectedItems(Array(sessionType?.length).fill(newSelectAll));
    };

    const handleSelectItem = (index) => {
      const newSelectedItems = [...selectedItems];
      newSelectedItems[index] = !newSelectedItems[index];
      setSelectedItems(newSelectedItems);
    };
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        // If the click is outside the dropdown and the dropdown is open, close it
        if (!event.target.closest('.dropdown-container') && isOpen) {
          setIsOpen(false);  // Close the dropdown
        }
      };
  
      // Add event listener for clicks
      window.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);

// __________________________ Type filter __________________________________



// __________________________ Faculty filter __________________________________

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [facultySelections, setFacultySelections] = useState([]);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
      if (sessions) {
        const initialSelections = sessions?.flatMap(session => session?.sessionFaculties).map(() => true);
        setFacultySelections(initialSelections);
      }
    }, [sessions]);

    useEffect(() => {
      const currentExcluded = sessions?.flatMap(session => 
        session?.sessionFaculties?.map(faculty => faculty?.facultyid)
      )?.filter((_, index) => !facultySelections[index]) || [];
      
      const sessionsWithNoFaculties = sessions?.filter(session => session?.sessionFaculties?.length === 0)
        .map(session => session?.ttttt_session_id);

      setExcludedFacultyIds(currentExcluded);
      setNoFacultySessionIds(sessionsWithNoFaculties);
    }, [facultySelections, sessions]);

    const toggleFacultySelection = (index) => {
      const updatedSelections = [...facultySelections];
      updatedSelections[index] = !updatedSelections[index];
      setFacultySelections(updatedSelections);
    };

    useEffect(() => {
      setAllWithoutFacultySelected(true)
    }, [])
    
    const toggleSelectAll = () => {
      const allSelected = facultySelections?.every(Boolean);
      const newSelections = Array(facultySelections?.length).fill(!allSelected);
    
      // Check if all items in newSelections are true or false
      const allTrue = newSelections?.every(Boolean);
      const allFalse = newSelections?.every(item => !item);
    
      if (allTrue) {
        setAllWithoutFacultySelected(true); // Change to false when all are selected
        setFacultySelections(newSelections); // Call here when all are selected
      } else if (allFalse) {
        if (!allWithoutFacultySelected) {
          setAllWithoutFacultySelected(true); // Change to true when none are selected
          setFacultySelections(Array(facultySelections?.length).fill(true)); // Set to true array
        } else {
          setAllWithoutFacultySelected(false); // Change to false when selections are made
          setFacultySelections(newSelections); // Call here when none are selected
        }
      } else {
        setAllWithoutFacultySelected(false); // Handle mixed case
        setFacultySelections(newSelections); // Call in mixed case as well
      }
    };
    
    const toggleSelectAllWithoutFaculty = () => {
      setAllWithoutFacultySelected(prev => !prev);
    };

    const filteredSessions = sessions?.filter(session => {
      const hasNoFaculties = session?.sessionFaculties?.length === 0;
      const isExcluded = excludedFacultyIds?.some(facultyId => session.sessionFaculties.some(faculty => faculty.facultyid === facultyId));

      return !allWithoutFacultySelected || hasNoFaculties || isExcluded;
    });

    useEffect(() => {
      const handleClickOutside = (event) => {
        // If the click is outside the dropdown and the dropdown is open, close it
        if (!event.target.closest('.dropdown-faculty-container') && dropdownOpen) {
          setDropdownOpen(false);  // Close the dropdown
        }
      };
  
      // Add event listener for clicks
      window.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownOpen]);

// __________________________ Faculty filter __________________________________

    const ScreenRef = useRef(null)
    
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(2);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      return `${day}-${month}-${year} `;
    };

    const formatTime = (inputString) => {
      let date;
    
      // Check if the input is in "YYYY-MM-DD HH:mm:ss" format (no 'T' separator)
      if (inputString.includes(' ')) {
        // For "YYYY-MM-DD HH:mm:ss", replace the space with 'T' to make it ISO format
        inputString = inputString.replace(' ', 'T');
        date = new Date(inputString); // Parse as an ISO string
      } else {
        // If it's just a time string (HH:mm or HH:mm AM/PM), handle it
        const timeRegex = /^(\d{2}):(\d{2})(\s?[APMapm]{2})?$/;
    
        const match = inputString.match(timeRegex);
    
        if (!match) {
          return 'Invalid time format'; // If input doesn't match expected time format
        }
    
        let [_, hours, minutes, ampm] = match;
        hours = parseInt(hours);
        minutes = parseInt(minutes);
    
        // If the time is in 12-hour format (AM/PM), adjust hours accordingly
        if (ampm) {
          ampm = ampm.toUpperCase().trim();
          if (ampm === 'PM' && hours !== 12) {
            hours += 12; // Convert PM hours to 24-hour format
          } else if (ampm === 'AM' && hours === 12) {
            hours = 0; // Convert 12 AM to 0 (midnight)
          }
        }
    
        // Ensure hours and minutes are valid
        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
          return 'Invalid time format';
        }
    
        // Create a valid date object from parsed time
        date = new Date(`1970-01-01T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`);
      }
    
      // If date parsing failed, return an error message
      if (isNaN(date.getTime())) {
        return 'Invalid time format';
      }
    
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
    
      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? String(hours).padStart(2, '0') : '12'; // '0' becomes '12'
    
      return `${hours}:${minutes} ${ampm}`;
    };

    const printTable = () => {
      const printWindow = window.open('', '_blank');
    
      // Define session type mapping
      const sessionTypeMapping = {
        'Academic': translations["Personled"] || "Person led",
        'Break': translations["Breaks"] || "Break",
        'Tour': translations["Tour"] || "Tour",
        'GroupDiscussion': translations["Group_discussion"] || "Group Discussion",
        'Assignment': translations["Assignment"] || "Assignment",
        'PT': translations["PT"] || "PT",
        'Sports': translations["Sports"] || "Sport",
        'Practical': translations["Practical"] || "Practical",
        'SelfPaced': translations["Self_paced"] || "Self paced",
      };
    
      // Initialize visibility flags for each column
      let columnVisibility = {
        "Day": false,
        "Week": false,
        "Module": false,
        "srno": false,
        // Fixed columns: these are always visible, so no condition is applied to them
        "Duration": true,
        "Subject": true,
        "Type": true,
        "Faculty": true,
        "Date": false,
      };
    
      // Loop through all sessions to determine which columns should be shown
      sessions.forEach(session => {
        const displayInfo = session.displayInfos || [];
    
        displayInfo.forEach(info => {
          if (info.value) {
            columnVisibility[info.name] = true;
          }
        });
      });
    
      // Remove duplicate sessions based on session ID (or any unique identifier)
      const uniqueSessions = sessions.filter((session, index, self) => 
        index === self.findIndex((t) => (
          t.ttttt_session_id === session.ttttt_session_id
        ))
      );
    
      // Prepare session rows
      const sessionRows = uniqueSessions?.map((session, index) => {
        const displayInfo = session.displayInfos || {};
    
        const faculties = session?.sessionFaculties?.map((faculty, facultyIndex) => {
          return `<span key="${facultyIndex}">${faculty.facultyname}</span>`;
        }).join(", "); // Join faculty names with commas
    
        // Use sessionTypeMapping for the session type
        const sessionType = sessionTypeMapping[session.session_type_name] || session.session_type_name || "Unknown";
    
        // Check if session.ttttt_type is 6 or 7 to conditionally add the start and end date
        let sessionContentDesc = `${session.ttttt_subject} - ${session.ttttt_content_desc}`;
        if (session.ttttt_type === 6 || session.ttttt_type === 7) {
          const startDate = formatDate(session.ttttt_session_dt);
          const endDate = formatDate(session.ttttt_session_end_time);
          const startTime = formatTime(session.ttttt_session_time);
          const endTime = formatTime(session.ttttt_session_end_time);
          sessionContentDesc += `<br>Start Date: ${startDate} ${startTime} - Last Date: ${endDate} ${endTime}`;
        }
    
        return `
          <tr key="${session.ttttt_session_id || index}">
            ${columnVisibility["Day"] ? `<td>${session.ttttt_session_day}</td>` : ''}
            ${columnVisibility["Week"] ? `<td>${session.ttttt_session_week}</td>` : ''}
            ${columnVisibility["Module"] ? `<td>${session.module}</td>` : ''}
            ${columnVisibility["srno"] ? `<td>${session.ttttt_session_no}</td>` : ''}
            ${columnVisibility["Date"] ? `<td>${formatDate(session.ttttt_session_dt)}</td>` : ''}
            ${columnVisibility["Duration"] ? `<td>${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}</td>` : ''}
            ${columnVisibility["Subject"] ? `<td>${sessionContentDesc}</td>` : ''}
            ${columnVisibility["Type"] ? `<td>${sessionType}</td>` : ''}
            ${columnVisibility["Faculty"] ? `<td>${faculties}</td>` : ''}
          </tr>
        `;
      }).join("");
    
      // Print HTML document
      printWindow.document.write(`
        <html>
          <head>
            <title>Session List Table</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: center;
              }
              th {
                background-color: #f2f2f2;
              }
              h2 {
                text-align: center;
                margin-bottom: 20px;
              }
              h3 {
                text-align: center;
                margin-bottom: 30px;
              }
            </style>
          </head>
          <body>
            <h2>${trainingDetails?.courseName || 'Course Name Not Available'} - ${trainingDetails?.trainingcode || 'Training Code Not Available'}</h2>
            <h3>Time Table Duration: ${formatDate(trainingDetails?.t_StartDate) || 'Start Date Not Available'} - ${formatDate(trainingDetails?.t_EndDate) || 'End Date Not Available'}</h3>
    
            <table>
              <thead>
                <tr>
                  ${columnVisibility["Day"] ? `<th>${translations["Days"] || "Days"}</th>` : ''}
                  ${columnVisibility["Week"] ? `<th>${translations["Week"] || "Week"}</th>` : ''}
                  ${columnVisibility["Module"] ? `<th>${translations["Module"] || "Module"}</th>` : ''}
                  ${columnVisibility["srno"] ? `<th>${translations["Srno"] || "Sr No"}</th>` : ''}
                  ${columnVisibility["Date"] ? `<th>${translations["Date"] || "Date"}</th>` : ''}
                  ${columnVisibility["Duration"] ? `<th>${translations["Duration"] || "Duration"}</th>` : ''}
                  ${columnVisibility["Subject"] ? `<th>${translations["Subject"] || "Subject"}</th>` : ''}
                  ${columnVisibility["Type"] ? `<th>${translations["Type"] || "Type"}</th>` : ''}
                  ${columnVisibility["Faculty"] ? `<th>${translations["Faculty"] || "Faculty"}</th>` : ''}
                </tr>
              </thead>
              <tbody>
                ${sessionRows}
              </tbody>
            </table>
          </body>
        </html>
      `);
    
      // Wait for the window to load and trigger the print dialog
      printWindow.document.close(); // Important to close the document for proper rendering
      printWindow.print(); // Open the print dialog
    };

    const downloadExcel = () => {
      // Define session type mapping
      const sessionTypeMapping = {
        'Academic': translations["Personled"] || "Person led",
        'Break': translations["Breaks"] || "Break",
        'Tour': translations["Tour"] || "Tour",
        'GroupDiscussion': translations["Group_discussion"] || "Group Discussion",
        'Assignment': translations["Assignment"] || "Assignment",
        'PT': translations["PT"] || "PT",
        'Sports': translations["Sports"] || "Sport",
        'Practical': translations["Practical"] || "Practical",
        'SelfPaced': translations["Self_paced"] || "Self paced",
      };
    
      // Initialize visibility flags for each column
      let columnVisibility = {
        "Day": false,
        "Week": false,
        "Module": false,
        "srno": false,
        // Fixed columns: these are always visible, so no condition is applied to them
        "Duration": true,
        "Subject": true,
        "Type": true,
        "Faculty": true,
        "Date": false,
      };
    
      // Loop through all sessions to determine which columns should be shown
      sessions.forEach(session => {
        const displayInfo = session.displayInfos || [];
    
        displayInfo.forEach(info => {
          if (info.value) {
            columnVisibility[info.name] = true;
          }
        });
      });
    
      // Remove duplicate sessions based on session ID (or any unique identifier)
      const uniqueSessions = sessions.filter((session, index, self) =>
        index === self.findIndex((t) => (
          t.ttttt_session_id === session.ttttt_session_id
        ))
      );
    
      // Prepare the rows for the Excel file, including headers
      const rows = [
        // Header row for Excel (same as the table headers)
        [
          columnVisibility["Day"] ? (translations["Days"] || "Days") : null,
          columnVisibility["Week"] ? (translations["Week"] || "Week") : null,
          columnVisibility["Module"] ? (translations["Module"] || "Module") : null,
          columnVisibility["srno"] ? (translations["Srno"] || "Sr No") : null,
          columnVisibility["Date"] ? (translations["Date"] || "Date") : null,
          columnVisibility["Duration"] ? (translations["Duration"] || "Duration") : null,
          columnVisibility["Subject"] ? (translations["Subject"] || "Subject") : null,
          columnVisibility["Type"] ? (translations["Type"] || "Type") : null,
          columnVisibility["Faculty"] ? (translations["Faculty"] || "Faculty") : null,
        ].filter(Boolean),  // Filter out nulls for columns that are not visible
    
        // Data rows (loop through unique sessions)
        ...uniqueSessions.map(session => {
          const sessionType = sessionTypeMapping[session.session_type_name] || session.session_type_name || "Unknown";
          const faculties = session?.sessionFaculties?.map(faculty => faculty.facultyname).join(", ") || "";
    
          // Prepare session row based on column visibility
          const row = [];
          if (columnVisibility["Day"]) row.push(session.ttttt_session_day);
          if (columnVisibility["Week"]) row.push(session.ttttt_session_week);
          if (columnVisibility["Module"]) row.push(session.module);
          if (columnVisibility["srno"]) row.push(session.ttttt_session_no);
          if (columnVisibility["Date"]) row.push(formatDate(session.ttttt_session_dt));
          if (columnVisibility["Duration"]) row.push(`${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`);
          if (columnVisibility["Subject"]) row.push(`${session.ttttt_subject} - ${session.ttttt_content_desc}`);
          if (columnVisibility["Type"]) row.push(sessionType);  // Mapped session type here
          if (columnVisibility["Faculty"]) row.push(faculties);
    
          return row;
        }) || []
      ];
    
      // Course details row (No need to span across columns in Excel)
      const courseDetails = [
        [
          `${trainingDetails?.courseName} - ${trainingDetails?.trainingcode}`
        ],
        [`Time Table Duration :  ${formatDate(trainingDetails?.t_StartDate) || 'Start Date Not Available'} - ${formatDate(trainingDetails?.t_EndDate) || 'End Date Not Available'}`]  // 1 column
      ];
    
      // Combine course details and session data
      const finalRows = [
        ...courseDetails,
        [],
        // Add a blank row for separation
        ...rows
      ];
    
      // Create a worksheet and workbook
      const ws = XLSX.utils.aoa_to_sheet(finalRows); // Array of arrays (rows)
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Session List");
    
      // Download the file as Excel
      XLSX.writeFile(wb, "session_list.xlsx");
    };

    const handleError = ()=>{
      toast.warning("No sessions Found")
    }

    useEffect(() => {
        return () => {
          document.body.style.overflowY = 'auto';
        };
    }, []);
  
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
      );
    }

    return (
      <div className='h-full w-full'>
          <div
              className='flex max-lg:grid max-lg:grid-cols-1 max-sm:grid-cols-1 max-lg:gap-4 items-center justify-between py-6 border-b-2'>
              <div className='flex max-sm:gap-4 max-sm:items-start max-sm:flex-col gap-10 items-center'>
                
                <div className='flex max-sm:items-start max-sm:gap-4 gap-10 max-sm:flex-col items-center'>
                  <span className='text-2xl'>{translations["Trg_period"] || "Training Period"}</span> 
                  <TwoMonthCalendar/>
                </div>

                <div className='flex  items-center gap-4'>
                    <input 
                      checked={
                        normalizeDate(initialAllowedStartDate).getTime() === normalizeDate(startDate).getTime()
                        &&
                        normalizeDate(initialAllowedEndDate).getTime() === normalizeDate(endDate).getTime() 
                      }
                      onClick={fillAllDates}
                      className='mt-[2px] max-sm:mt-0' 
                      type="checkbox" 
                    />
                    <span>{translations["Show_all_session"] || "Show All Sessions"}</span>
                </div>

              </div>

              <div className="relative inline-block text-left dropdown-container">
                <button
                  className="inline-flex w-full max-lg:max-w-72 max-lg:justify-between pl-4 max-mid:max-w-96 items-center relative gap-x-2 overflow-hidden rounded-md bg-white px-2.5 py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {translations["Filter_by_Type"] || "Filter By Type"}
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                  />
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                  <div className="absolute bg-white max-phone:w-[90vw] min-h-fit max-h-[60vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                    <div className="py-1 w-full">
                      {/* Select All Option */}
                      <button
                        className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                        onClick={handleSelectAll}
                      >
                        <span className="flex py-3 items-center gap-2">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            readOnly
                            className="mr-2 mt-0"
                          />
                          <span className="flex items-center">
                            {translations["All"] || "All"}
                          </span>
                        </span>
                      </button>

                      {/* Session Type Items */}
                      {sessionType.map((session, index) => (
                        <button
                          key={session.id}
                          className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                          onClick={() => handleSelectItem(index)}
                        >
                          <span className="flex py-3 items-center gap-2">
                            <input
                              type="checkbox"
                              checked={selectedItems[index]}
                              readOnly
                              className="mr-2 mt-0"
                            />
                            <i className={session.displayClass}></i>
                            <span>
                              {
                                session.name === 'Academic' ? (
                                  translations["Personled"] || "Person led"
                                ) : 
                                session.name === 'Break' ? (
                                  translations["Breaks"] || "Break"
                                ) : 
                                session.name === 'Tour' ? (
                                  translations["Tour"] || "Tour"
                                ) : 
                                session.name === 'GroupDiscussion' ? (
                                  translations["Group_discussion"] || "Group Discussion"
                                ) : 
                                session.name === 'Presentation' ? (
                                  translations["Presentation"] || "Presentation"
                                ) : 
                                session.name === 'Assignment' ? (
                                  translations["Assignment"] || "Assignment"
                                ) : 
                                session.name === 'Test' ? (
                                  translations["Test"] || "Test"
                                ) :
                                session.name === 'PT' ? (
                                  translations["PT"] || "PT"
                                ) : 
                                session.name === 'Sports' ? (
                                  translations["Sport"] || "Sports"
                                ) : 
                                session.name === 'Practical' ? (
                                  translations["Practical"] || "Practical"
                                ) :
                                session.name === 'SelfPaced' ? (
                                  translations["Self_paced"] || "Self Paced"
                                ) :
                                  session.name
                              }
                            </span>
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="relative inline-block text-left dropdown-faculty-container">
                <button
                  onClick={toggleDropdown}
                  className="inline-flex w-full max-lg:max-w-72 max-lg:justify-between pl-4 max-mid:max-w-96 items-center relative gap-x-2 overflow-hidden rounded-md bg-white px-2.5 py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                >
                  {translations["Filter_by_faculty"] || "Filter By Speaker"}
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                  />
                </button>

                {dropdownOpen && (
                  <div className="absolute bg-white max-phone:w-[90vw] min-h-fit max-h-[60vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                    <div className="py-1 w-full">
                      <button
                        className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                        onClick={toggleSelectAll}
                      >
                        <span className="flex py-3 items-center gap-2">
                          <input
                            type="checkbox"
                            checked={facultySelections.every(Boolean) && allWithoutFacultySelected}
                            readOnly
                            className="mr-2 mt-0"
                          />
                          <span className="flex items-center">
                            {translations["All"] || "All"}
                          </span>
                        </span>
                      </button>

                      <button
                        className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                        onClick={toggleSelectAllWithoutFaculty}
                        >
                        <span className="flex py-3 items-center gap-2">
                          <input
                            type="checkbox"
                            checked={allWithoutFacultySelected}
                            readOnly
                            className="mr-2 mt-0"
                          />
                          <span>All Without Faculty</span>
                        </span>
                      </button>

                      {
                        [...new Set(sessions?.flatMap(session => 
                          session.sessionFaculties?.map(faculty => faculty.facultyid) || []
                        ))].map((facultyId, index) => {
                          const faculty = sessions?.flatMap(session => session.sessionFaculties).find(f => f.facultyid === facultyId);
                          return (
                          <button
                            key={faculty.facultyid}
                            className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                            onClick={() => toggleFacultySelection(index)}
                          >
                            <span className="flex py-3 items-center gap-2">
                              <input
                                type="checkbox"
                                checked={facultySelections[index]}
                                className="mr-2 mt-0"
                                readOnly
                              />
                              <span>{faculty?.facultyname}</span>
                            </span>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
          </div>

          <div className='w-full flex flex-col py-6 gap-2' >
            <div className='flex justify-between max-lg:flex-col max-lg:items-start max-lg:gap-4 items-center '>
              <div className='flex items-center gap-2 max-lg:grid max-sm:grid-cols-2 max-lg:grid-cols-3 '>
                
                {
                  createbutton === null || (Array.isArray(createbutton) && createbutton?.length === 0) ?
                  null
                  :
                  <>
                    <button
                      title={translations["Create_Session"] || "Create Session"}
                      style={{ backgroundColor: theme?.palette?.primary?.main }} 
                      className='text-white py-3 px-5 rounded-lg'
                      // onClick={()=>setShowModal(true)}
                    > 
                      {translations["Create_Session"] || "Create Session"}
                    </button>

                    <button 
                      title={translations["Import_session"] || "Import Session"}
                      style={{ backgroundColor: theme?.palette?.primary?.main }} 
                      className='text-white py-3 px-5 rounded-lg'
                    > 
                      {translations["Import_session"] || "Import Session"}
                    </button>

                    <button 
                      title={translations["Recurring_session"] || "Recurring Session"}  
                      style={{ backgroundColor: theme?.palette?.primary?.main }} 
                      className='text-white py-3 px-5 rounded-lg'
                    > 
                      {translations["Recurring_session"] || "Recurring Session"}                  
                      
                    </button>

                    <button 
                      title={translations["Import_session"] || "Import_from_suit"}
                      style={{ backgroundColor: theme?.palette?.primary?.main }} 
                      className='text-white flex items-center gap-4 py-3 px-5 rounded-lg'
                    > 
                      <FaSuitcase/>
                      {translations["Import_session"] || "Import_from_suit"}                  
                    </button>

                    <button 
                      title={translations["Add_participant"] || "Add Participant"}
                      style={{ backgroundColor: theme?.palette?.primary?.main }} 
                      className='text-white flex items-center gap-2 py-3 px-5 rounded-lg'
                    > 
                      <FaUser/>
                      {translations["Add_participant"] || "Add Participant"}                  
                    </button>
                  </>
                }

              </div>
              {
                selectedUserType != 5 && (
                    (contentlibrarybutton === null || (Array.isArray(contentlibrarybutton) && contentlibrarybutton.length === 0))
                    ? null
                    : (
                        <button 
                            title={translations["Content_library"] || "Content Library"}
                            style={{ backgroundColor: theme?.palette?.primary?.main }} 
                            className='text-white py-3 px-5 rounded-lg'
                        >
                            {translations["Content_library"] || "Content Library"}
                        </button>
                    )
                )
              }

            </div>
            
            <div className='flex max-lg:flex-col max-lg:items-start justify-between items-end gap-2'>
              <p
                style={{ color: theme?.palette?.primary?.main }} 
                className='flex items-center m-0'
              >
                To View/Read Content Click On Littera Room Icon ({<IoRocketSharp/>})
              </p>

              <div className='flex max-sm:grid max-sm:grid-cols-1 gap-2'>
                <div className='flex max-phone:grid max-phone:grid-cols-1 gap-2'>
                  {/* {durationtype?.activity <= 0 && ( */}
                  {durationtype?.activity > 0 && (
                    <button
                      title={`Activity ${durationtype?.activity} - ${durationtype?.durationtype}`}
                      className='text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg'
                    >
                      <LuActivity /> {translations["Activity"] || "Activity"} {durationtype?.activity} - {durationtype?.durationtype}
                    </button>
                  )}
                  
                  {/* {durationtype?.practical <= 0 && ( */}
                  {durationtype?.practical > 0 && (
                    <button
                      title={`Practical ${durationtype?.practical} - ${durationtype?.durationtype}`}
                      className='text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg'
                    >
                      <IoFlask /> {translations["Practical"] || "Practical"} {durationtype?.practical} - {durationtype?.durationtype}
                    </button>
                  )}
                  
                  {/* {durationtype?.theory <= 0 && ( */}
                  {durationtype?.theory > 0 && (
                    <button
                      title={`Theory ${durationtype?.theory} - ${durationtype?.durationtype}`}
                      className='text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg'
                    >
                      <FaBook /> 
                      {translations["Theory"] || "Theory"} {durationtype?.theory} - {durationtype?.durationtype}
                    </button>
                  )}
                </div>

                  <div className='flex gap-2'>
                    <button
                      onClick={sessions?.length > 0 ? printTable : handleError}
                      title='Print'
                      className='text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                      >
                      <FaPrint/> 
                    </button>

                    <button
                      onClick={sessions?.length > 0 ? downloadExcel : handleError }
                      title='Save as excel'
                      className='text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                      >
                      <RiFileExcel2Fill/> 
                    </button>
                  </div>
              </div>
            </div>
          </div>
          
          {
            showModal ? <CreateSessionFromCalendar/> : null
          }
          <div ref={ScreenRef}>
            <NestedList />
          </div>
      </div>
    )
}

export default SessionList