import React, { useEffect, useState } from "react";
import { Form, Select, Button, Tag, Typography, Space, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import 'antd/dist/reset.css'; // Import Ant Design CSS
import Breadcrumb from "../commonComponent/Breadcrumbs";
import { useUser } from "../../UserContext";

const { Option } = Select;

const TestDescription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setTestDataOther, setTestalldata, setPayload } = useUser();

  const configDataurl = useUser();
  const config = configDataurl?.configDataurl;

  const [categories, setCategories] = useState([]);
  const [skillTags, setSkillTags] = useState([]);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSkillTags, setSelectedSkillTags] = useState([]);
  const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState('');
  const [testData, setTestData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    keyword,
    trainingid,
    sessionid,
    trainingcategoryid
  } = location.state || {};

  console.log("keyword", keyword);

  // Fetching test data and difficulty levels
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [selfTest, difficultyLevelsRes] = await Promise.all([
          axios.get(`${config.REACT_APP_API_URL}/Get_Self_Test_Configuration`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          }),
          axios.get(`${config.REACT_APP_EVAL_API_PATH}/QuestionDifficulties`, {
            headers: { "Content-Type": "application/json", "API-Key": config?.REACT_APP_API_KEY }
          })
        ]);

        setTestData(selfTest.data.no_of_question);
        setTestalldata(selfTest.data);
        setDifficultyLevels(difficultyLevelsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [config]);

  // Fetching categories based on trainingcategoryid
  // Fetching categories based on trainingcategoryid
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let categoriesRes;

        if (trainingcategoryid) {
          // Fetch categories based on the trainingcategoryid
          categoriesRes = await axios.get(`${config.REACT_APP_API_URL}/Categories?categoryid=${trainingcategoryid}`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          });
        } else {
          // Fetch all categories if no trainingcategoryid is provided
          categoriesRes = await axios.get(`${config.REACT_APP_API_URL}/Categories`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          });
        }

        setCategories(categoriesRes.data); // Set categories

        // If categories are fetched and the trainingcategoryid exists, set it as the selected value
        if (categoriesRes.data.length > 0) {
          if (trainingcategoryid) {
            // Try to find the category based on trainingcategoryid and set it as selected
            const foundCategory = categoriesRes.data.find(
              category => category.trainingCategoryId === trainingcategoryid
            );
            if (foundCategory) {
              setSelectedCategory(foundCategory.trainingCategoryId); // Set the category as selected
            }
          } else {
            // Default to the first category if no trainingcategoryid exists
            setSelectedCategory(categoriesRes.data[0].trainingCategoryId);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [config, trainingcategoryid]); // Only re-run if config or trainingcategoryid changes

  // Fetching skill tags based on selected category and keyword
  // Fetching skill tags based on selected category and keyword
  useEffect(() => {
    if (selectedCategory) {
      const fetchSkillTags = async () => {
        try {
          // Construct the API URL based on the selected category
          let apiUrl = `${config.REACT_APP_EVAL_API_PATH}/Tests/Category_Question_Tags?trainingcategoryid=${selectedCategory}`;

          // If keyword is present, include it in the query
          if (keyword) {
            apiUrl += `&keyword=${encodeURIComponent(keyword)}`;
          }

          const skillTagsRes = await axios.get(apiUrl, {
            headers: { "API-Key": config?.REACT_APP_API_KEY }
          });

          // Filter skill tags by keyword if available
          let filteredTags = skillTagsRes.data;
          if (keyword) {
            // Only keep tags that include the keyword (case-insensitive)
            filteredTags = skillTagsRes.data.filter(tag =>
              tag.tags.toLowerCase().includes(keyword.toLowerCase())
            );
          }

          // If no tags match, set empty array to clear the dropdown
          if (filteredTags.length === 0) {
            setSkillTags([ ]);
          } else {
            setSkillTags(filteredTags);
          }
        } catch (error) {
          console.error('Error fetching skill tags:', error);
          setSkillTags([]); // In case of an error, clear skill tags
        }
      };

      fetchSkillTags();
    } else {
      setSkillTags([]); // Clear skill tags if no category is selected
    }
  }, [selectedCategory, keyword, config]); // Re-run the effect when category or keyword changes


  // Automatically select skill tags based on the keyword if matches exist
  useEffect(() => {
    if (keyword && skillTags.length > 0) {
      const matchingTags = skillTags.filter(tag =>
        tag.tags.toLowerCase().includes(keyword.toLowerCase())
      );

      if (matchingTags.length > 0) {
        setSelectedSkillTags(matchingTags.map(tag => tag.tags.toLowerCase()));
      }
    }
  }, [keyword, skillTags]); // Trigger when keyword or skillTags change

  // Handlers for form changes
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleSkillTagsChange = (value) => {
    setSelectedSkillTags(value);
  };

  const handleDifficultyLevelChange = (value) => {
    setSelectedDifficultyLevel(value);
  };

  // Form submit handler
  const handleSubmit = async () => {
    if (!selectedCategory || !selectedSkillTags.length) {
      return;
    }

    const skillSet = selectedSkillTags.join(",");
    const payload = {
      skillSet,
      questionCount: testData,
      questionDifficutyID: selectedDifficultyLevel,
      trainingCategory: selectedCategory,
      trainingid: trainingid,
      sessionid: sessionid
    };

    try {
      const response = await axios.post(`${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`, payload, {
        headers: {
          "API-Key": "UHJhdGhhbXNvZnRTZWNyZXRLZXkxMjM="
        }
      });

      setPayload(payload);
      const responseData = response.data;
      setTestDataOther(responseData);

      if (responseData.questions.length < testData) {
        setModalContent('Number of Questions in selected tags and level are less than the required questions to conduct the test. Please Add More Questions.');
        setIsModalVisible(true);
      } else {
        navigate('/start-test');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  // Modal handlers
  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const paths = [
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Self Test', url: '' }
  ];

  return (
    <>
      <Breadcrumb paths={paths} />
      <div style={{
        padding: '24px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        marginLeft: "1%",
        margin: 'auto',
        marginTop: '10px',
        width: "98%"
      }}>
        <Typography.Title level={4} style={{ marginBottom: '16px' }}>
          Test Description
        </Typography.Title>

        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Category" name="category" rules={[{ required: true, message: 'Please select a category!' }]}>
            <Select
              value={selectedCategory}  // This ensures the dropdown reflects the selected category
              onChange={handleCategoryChange}
              placeholder="Select a category"
              allowClear
              loading={categories.length === 0} // Optionally show loading if categories are being fetched
            >
              {categories.map(category => (
                <Option key={category.trainingCategoryId} value={category.trainingCategoryId}>
                  {category.trainingCategoryName}
                </Option>
              ))}
            </Select>
          </Form.Item>


          <Form.Item label="Skill Tags" name="skillTags" rules={[{ required: true, message: 'Please select skill tags!' }]}>
            <Select
              mode="multiple"
              value={selectedSkillTags}
              onChange={handleSkillTagsChange}
              placeholder="Select skill tags"
              tagRender={(props) => (
                <Tag color="blue" {...props}>
                  {props.value}
                </Tag>
              )}
            >
              {skillTags.map(tag => (
                <Option key={tag.id} value={tag.tags.toLowerCase()}>
                  {tag.tags.toLowerCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Difficulty Level" name="difficultyLevel">
            <Select value={selectedDifficultyLevel} onChange={handleDifficultyLevelChange} placeholder="Select difficulty level" allowClear>
              {difficultyLevels.map(level => (
                <Option key={level.id} value={level.questionDifficultyID}>
                  {level.description.toLowerCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="default" style={{ backgroundColor: '#f0f0f0' }}>
                Close
              </Button>
              <Button type="primary" htmlType="submit">
                Start
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      <Modal
        title="Insufficient Questions"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="OK"
      >
        <p>{modalContent}</p>
      </Modal>
    </>
  );
};

export default TestDescription;
