import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent, Typography,CircularProgress } from '@mui/material';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import styled from 'styled-components';
import { useConfig } from '../../ConfigContext';
import { Input, Select } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useUser } from '../../UserContext';

const { Option } = Select;

// StyledTableCell for table
const StyledTableCell = styled(TableCell)`
    border-left: 1px solid #ccc;
    text-align: center;
`;

// Card styles
const cardStyle = {
    marginBottom: '16px',
};

// Main component
const FeedbackList = () => {
    const { selectedUserType } = useUser();
    const { selectedYear } = useUser();
    const config = useConfig();
    const [feedbackData, setFeedbackData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [totalItems, setTotalItems] = useState(0); // Total items
    const [searchQuery, setSearchQuery] = useState(); // State for search query
    const [searchCriteria, setSearchCriteria] = useState('trainingcode'); // State for selected search criteria

    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Feedback', url: '/Feedback/Feedback' }
    ];

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const user = JSON.parse(localStorage.getItem('user'));
    const startDate = `${selectedYear.split('-')[0]}-04-01`;
    const endDate = `${selectedYear.split('-')[1]}-03-31`;
    useEffect(() => {
        const userid = user.userdetails.agencyid;
        const fetchData = async () => {
            const url = `${config.REACT_APP_API_URL}/TrainingFeedback?usertype=${selectedUserType}&userid=${userid}&startdate=${startDate}&enddate=${endDate}`;
            let payload = null;  // Initially set payload to null

            // If searchQuery has a value, include the searchCriteria in the payload
            if (searchQuery && searchQuery.trim() !== "") {
                payload = {
                    searchCriteria: [
                        {
                            column: searchCriteria,   // Search column
                            value: searchQuery,       // Search value
                            condition: '=',           // Search condition (can be 'like', '=', etc.)
                            nextoperator: 'Or',       // Next operator for multiple conditions
                        },
                    ],
                };
            }


            try {
                const response = await axios.post(url, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'APIKey': config.REACT_APP_API_KEY,
                    },
                });

                console.log("response.data", response.data);
                setFeedbackData(response.data.items);
                setTotalItems(response.data.totalCount);
                setTotalPages(response.data.totalPages);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };


        fetchData();
    }, [selectedYear, currentPage, pageSize, searchQuery, searchCriteria, selectedUserType]); // Add searchQuery and searchCriteria to dependencies

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        }
    };

    const handleClick = async (surveyid, groupid, sharefeedbackid) => {
        const userid = user.userdetails.agencyid;
        const url = `${config.REACT_APP_API_URL}/Check_Feedback_Status?participantid=${userid}&sharefeedbackid=${sharefeedbackid}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'APIKey': config.REACT_APP_API_KEY, // Ensure this is correct
                },
            });

            console.log('Response Data:', response.data);
            if (response.data === 0) {
                window.open(`${config.LITTERA_CDN_BASE_URL}/Feedback360/ShareFeedback?surveyid=${surveyid}&groupid=${groupid}&sharesurveyid=${sharefeedbackid}`);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const FeedbackCard = ({ feedback, index }) => (
        <Card style={cardStyle} key={feedback.trainingid}>
            <CardContent>
                <Typography variant="h6">Sr. No: {index + 1}</Typography>
                <Typography variant="body1">Training Code: {feedback.trainingcode}</Typography>
                <Typography variant="body1">Training Title: {feedback.trainingtitle}</Typography>
                <Typography variant="body1">Feedback Title: {feedback.feedbacktitle}</Typography>
                <Typography variant="body1">Status: {feedback.status_txt}</Typography>
                <Button variant="contained" color="primary" style={{ marginTop: '16px' }}>
                    {feedback.tssr_responsdant_mobile ? (
                        <span className="fa fa-thumbs-o-up" title="Feedback Given"></span>
                    ) : feedback.status === 1 ? (
                        <span
                            className="fa fa-play"
                            title="Feedback Pending"
                            onClick={() => handleClick(feedback.sureveyid, feedback.groupid, feedback.sharefeedbackid)}
                        ></span>
                    ) : (
                        <span
                            className="fa fa-play"
                            title="Feedback Not Started"
                            style={{ opacity: 0.5 }}
                        ></span>
                    )}
                </Button>
            </CardContent>
        </Card>
    );

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handleSearchCriteriaChange = (value) => {
        setSearchCriteria(value);
        setCurrentPage(1); // Reset to first page on criteria change
    };

    if (isLoading) return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
    </div>);
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <Breadcrumb paths={paths} />
            <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%' }}>
                <Input
                    placeholder="Search"
                    style={{ width: '80%', padding: '8px' }}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                />
                <Select
                    placeholder="Select Search Criteria" className='selectsearch'
                    style={{ height: '40px', maxWidth: "400px", width: '30%' }}
                    bordered
                    value={searchCriteria}
                    onChange={handleSearchCriteriaChange}
                >
                    <Option value="trainingcode">
                        Training Code
                    </Option>
                    <Option value="trainingtitle">
                        Training Title
                    </Option>
                    <Option value="status_txt">
                        Feedback Title
                    </Option>
                    {/* Add more Option elements here */}
                </Select>

            </div>
            {isMobileView ? (
                <div style={{ width: '96%', margin: 'auto', marginTop: '10px' }}>
                    {feedbackData.map((feedback, index) => (
                        <FeedbackCard key={feedback.trainingid} feedback={feedback} index={index} />
                    ))}
                </div>
            ) : (
                <TableContainer component={Paper} style={{ width: "96%", margin: "auto", marginTop: "10px" }}>
                    <Table>
                        <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
                            <TableRow>
                                <StyledTableCell className='feedbck-titel'>Sr. No.</StyledTableCell>
                                <StyledTableCell className='feedbck-titel'>Training Code</StyledTableCell>
                                <StyledTableCell className='feedbck-titel'>Training Title</StyledTableCell>
                                <StyledTableCell className='feedbck-titel'>Feedback Title</StyledTableCell>
                                <StyledTableCell className='feedbck-titel'>Status</StyledTableCell>
                                <StyledTableCell className='feedbck-titel'>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {feedbackData.map((feedback, index) => (
                                <TableRow key={feedback.trainingid}>
                                    <StyledTableCell className="feedback-row">{index + 1}</StyledTableCell>
                                    <StyledTableCell className="feedback-row">{feedback.trainingcode}</StyledTableCell>
                                    <StyledTableCell className="feedback-row">{feedback.trainingtitle}</StyledTableCell>
                                    <StyledTableCell className="feedback-row">{feedback.feedbacktitle}</StyledTableCell>
                                    <StyledTableCell className="feedback-row">{feedback.status_txt}</StyledTableCell>
                                    <StyledTableCell style={{ cursor: "pointer" }}>
                                        {feedback.tssr_responsdant_mobile ? (
                                            <span className="fa fa-thumbs-o-up" title="Feedback Given"></span>
                                        ) : feedback.status === 1 ? (
                                            <span
                                                className="fa fa-play"
                                                title="Feedback Pending"
                                                onClick={() => handleClick(feedback.sureveyid, feedback.groupid, feedback.sharefeedbackid)}
                                            ></span>
                                        ) : (
                                            <span
                                                className="fa fa-play"
                                                title="Feedback Not Started"
                                                style={{ opacity: 0.5 }}
                                            ></span>
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <div style={{ textAlign: 'end', margin: '20px 10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

export default FeedbackList;
