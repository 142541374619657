import React, { useState, useEffect } from 'react';
import { Collapse, Modal, Button, Input, Tooltip, Avatar, Badge } from 'antd';
import { CommentOutlined, MessageOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import styled from 'styled-components';  // Import styled-components
import { useConfig } from '../../ConfigContext';
import moment from 'moment';  // To format the date
import { v4 as uuidv4 } from 'uuid';  // Importing uuid
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Don't forget to import the CSS!

const { Panel } = Collapse;

const CommentsModal = ({ closeModal, participantId }) => {
    const config = useConfig();
    const [openPanels, setOpenPanels] = useState([]); // Tracks active panels
    const [searchText, setSearchText] = useState(''); // Search input state
    const [data, setData] = useState([]); // Data to hold comments
    const [isLoading, setIsLoading] = useState(false); // Loading state for API calls
    const [activeReply, setActiveReply] = useState(null); // Tracks which panel has the reply input
    const [activeComment, setActiveComment] = useState(null); // Tracks which panel has the comment input
    const [commentText, setCommentText] = useState(''); // Stores the comment text
    const [replyText, setReplyText] = useState(''); // Stores the reply text
    const user = JSON.parse(localStorage.getItem('user'));

    // Fetch data using useEffect
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const participantid = user.userdetails.agencyid;
            const url = `${config.REACT_APP_API_URL}/Get_Comments?assignmentid=${participantId}&trainingid=6fdc7ffa-a953-e6ff-9e7d-aeb84204749b&PageNumber=1&PageSize=10&participantid=${participantid}`;

            try {
                const response = await axios.post(url, null, {
                    headers: {
                        'Content-Type': 'application/json',
                        'APIKey': config.REACT_APP_API_KEY,
                    },
                });
                setData(response.data.items); // Set data from API
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [participantId]);

    // Handle comment and reply toggles
    const handleCommentClick = (panelKey) => {
        setActiveComment(activeComment === panelKey ? null : panelKey); // Toggle comment section visibility
        setActiveReply(null); // Close reply section if comment section is opened
    };

    const handleReplyClick = (panelKey) => {
        setActiveReply(activeReply === panelKey ? null : panelKey); // Toggle reply section visibility
        setActiveComment(null); // Close comment section if reply section is opened
    };
    const uid = uuidv4();
    // Handle sending the reply (for demonstration purposes, currently no API call)
    const handleSendReply = async (participantid) => {
        const commentedby = user.userdetails.agencyid;  // Assuming the agency ID is the user ID
        if (!replyText.trim()) {
            console.log('Reply text is empty');
            return;
        }

        const payload = {
            taac_commentid: uid,
            taac_AssignmentID: participantId,
            taac_Participantid: participantid,  // Use `uid` instead of `taac_Participantid`
            taac_commentedby: commentedby,
            taac_comment: replyText,
            taac_createdon: new Date().toISOString().split('T')[0],
        }

        console.log('Sending Reply:', payload);

        try {
            // API call to send the comment data using axios
            const response = await axios.post(`${config.REACT_APP_API_URL}/InsertComment`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'APIKey': config.REACT_APP_API_KEY,
                },
            });

            // Handle the response
            if (response.status === 200) {
                console.log('Reply Sent Successfully:', response.data);
                setReplyText(''); // Clear reply text after sending
                toast.success('Reply Sent Successfully!');
            } else {
                console.error('Error sending reply:', response.data);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };

    return (
        <StyledModal visible={true} onCancel={closeModal} footer={[
            <Button key="cancel" onClick={closeModal}>Cancel</Button>,
            <Button key="ok" type="primary" onClick={closeModal}>OK</Button>,
        ]}>
            <div className="modal-header" style={{ display: 'flex', alignItems: 'center', borderBottom: 'none' }}>
                <h3>Comments</h3>
            </div>

            {/* Search Input Section */}
            <div style={{ width: '100%', marginBottom: 20 }}>
                <Input
                    placeholder="Search Participant Name"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: '100%' }}
                    prefix={<SearchOutlined />}
                />
            </div>

            <Collapse
                accordion
                activeKey={openPanels}
                onChange={setOpenPanels}
                style={{ marginTop: '10px' }}
            >
                {data?.map((item, index) => (
                    <Panel header={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Display Participant Name and Avatar */}
                            <Avatar
                                size={40}
                                src={item.participant[0]?.participantphoto || 'default-avatar.png'}
                                style={{ marginRight: 10 }}
                            />
                            <span>{item.participant[0]?.participantname}</span>

                            {/* Comment and Reply icons */}
                            <Tooltip title="Comment">
                                <Button
                                    icon={<CommentOutlined />}
                                    style={{ backgroundColor: '#f5f5f5', border: 'none', marginLeft: 'auto' }}
                                    onClick={() => handleCommentClick(index)} // Toggle comment box
                                />
                            </Tooltip>
                            <span>
                                <Badge
                                    count={item.participant[0]?.comment?.length || 0} // Display the length of the comment array
                                    style={{ backgroundColor: '#FFA500' }}
                                    showZero // This ensures that 0 is shown when there are no comments
                                    onClick={() => handleCommentClick(index)} />
                            </span>
                            <Tooltip title="Reply">
                                <Button
                                    icon={<MessageOutlined />}
                                    style={{ backgroundColor: '#f5f5f5', border: 'none' }}
                                    onClick={() => handleReplyClick(index)} // Toggle reply box
                                />
                            </Tooltip>
                        </div>
                    } key={item.assignment}>
                        {/* Comments List */}
                        {activeComment === index && item.participant[0]?.comment.map((comment, commentIndex) => (
                            <div key={commentIndex} style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        size={24}
                                        src={comment.commentedbyohoto || 'default-avatar.png'}
                                        style={{ marginRight: 10 }}
                                    />
                                    <div>
                                        <strong>{comment.commentedby}</strong>
                                        <div>{moment(comment.taac_createdon).format('MMMM Do YYYY, h:mm A')}</div>
                                        <p>{comment.taac_comment}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* Conditionally render Reply Text Box */}
                        {activeReply === index && (
                            <div style={{ marginTop: '10px' }}>
                                <Input.TextArea
                                    rows={4}
                                    value={replyText}
                                    onChange={(e) => setReplyText(e.target.value)}
                                    placeholder="Write your reply..."
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginTop: '10px', }}
                                    onClick={() => handleSendReply(item.participant[0]?.participantid)}  // Pass participantid here
                                >
                                    Send
                                </Button>

                            </div>
                        )}
                    </Panel>
                ))}
            </Collapse>
        </StyledModal>
    );
};

export default CommentsModal;

// Styled Components for modal and layout
const StyledModal = styled(Modal)`
  .ant-modal {
    width: 55% !important;  /* Default width for larger screens */
  }
  .ant-collapse-header .ant-collapse-expand-icon {
    display: none !important;
  }
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .ant-modal {
      width: 90% !important;  /* Set width to 90% on mobile screens */
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .left-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;
