import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Use to extract params from URL
import axios from 'axios';
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { Collapse, Row, Col, Button, Typography, Tooltip } from 'antd';
import { UploadOutlined, DownloadOutlined, CommentOutlined, UnlockOutlined, LockOutlined } from '@ant-design/icons';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import { Cancel as CancelIcon } from '@mui/icons-material';
import moment from 'moment';
const { Panel } = Collapse;
const { Text } = Typography;

const AssignmentDetail = () => {
    const { id } = useParams(); // Get the trainingid from the URL
    const [assignmentDetails, setAssignmentDetails] = useState(null);
    const config = useConfig();

    useEffect(() => {

        const fetchAssignmentDetails = async () => {
            try {
                const response = await axios.get(
                    `${config.REACT_APP_API_URL}/AssignmentDetail?assignmentid=${id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'APIKey': config.REACT_APP_API_KEY, // Ensure this is correct
                        }
                    });
                // Assuming response.data is an array and we need the first item
                if (response.data && response.data.length > 0) {
                    setAssignmentDetails(response.data[0]);
                    console.log("assignmentDetails", assignmentDetails)
                } else {
                    console.log('No assignment details found.');
                }
            } catch (error) {
                console.error('Error fetching assignment details:', error);
            }
        };

        fetchAssignmentDetails();
    }, []);

    // Check if data is still loading
    if (!assignmentDetails) return <p>Loading...</p>;

    const { assignmentName
        , title, ttttt_session_dt, ttttt_session_end_time, assesmentQuestions, maxMarks, facultyname, instructions, tag, status, assignmentTypeName, uploadpath } = assignmentDetails;

    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Assignment Details', url: '/Feedback/Feedback' }
    ];
    const cleanedText = assesmentQuestions.replace(/<\/?[^>]+(>|$)/g, '');




    return (
        <>
            <Breadcrumb paths={paths} />
            <div className="assignment-details" style={{ backgroundColor: "white" }}>
                <div style={{ padding: "30px", border: "1px solid #c9c0c0", borderRadius: "6px" }}>
                    <Panel
                        header={
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <h5 style={{ textTransform: 'capitalize', display: 'inline-block' }}>
                                        {assignmentName} <sup style={{ color: 'green' }}>({(assignmentTypeName)})</sup>
                                    </h5>

                                    <Row gutter={[8, 8]} align="middle">
                                        <Col>
                                            <p style={{ margin: 0 }}>
                                                <strong>Start On:</strong>
                                                <Text>{moment(ttttt_session_dt).format('DD/MM/YYYY HH:mm')}</Text>
                                            </p>
                                        </Col>
                                        <Col>
                                            <Tooltip title={status == '0' ? 'Not started' :
                                                status == '1' ? 'Open' :
                                                    status === '2' ? 'Pause' :
                                                        status == '3' ? 'Closed' :
                                                            status == '-1' ? 'Cancelled' :
                                                                status == '4' ? 'Approved' : 'Unknown'}>
                                                {status == '0' && <i className="fa fa-lock" style={{ color: 'orange' }} title="Not started" />}
                                                {status == '1' && <i className="fa fa-unlock-alt" style={{ color: 'green' }} title="Open" />}
                                                {status == '2' && <i className="fa fa-pause" style={{ color: 'orange' }} title="Pause" />}
                                                {status == '3' && <i className="fa fa-lock" style={{ color: 'green' }} title="Closed" />}
                                                {status == '-1' && <CancelIcon style={{ color: 'red' }} />}
                                                {status == '4' && <i className="fa fa-check" style={{ color: 'green' }} title="Approved" />}
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    <p style={{ marginTop: "10px" }}>
                                        <strong>Last Date:</strong> <Text>{moment(ttttt_session_end_time).format('DD/MM/YYYY HH:mm')}</Text>
                                    </p>
                                    <p>
                                        <strong>Description:</strong><div
                                            dangerouslySetInnerHTML={{ __html: assesmentQuestions }}
                                        />
                                    </p>
                                    <p>
                                        <strong>Marks:</strong> <Text>{maxMarks}</Text>
                                    </p>
                                    <p>
                                        <strong>Faculty:</strong> <Text>{facultyname}</Text>
                                    </p>
                                    <p>
                                        <strong>Instructions:</strong> <Text>{instructions}</Text>
                                    </p>
                                    <p>
                                        <strong>Tag:</strong> <Text>{tag}</Text>
                                    </p>

                                    {uploadpath && uploadpath.trim() !== '' ? (
                                        <Tooltip title="Download">
                                            <Button
                                                icon={<DownloadOutlined />}
                                                onClick={() => {
                                                    // Concatenate the base URL and the upload path
                                                    const fullDownloadUrl = `${config.REACT_APP_API_URL}${uploadpath}`;
                                                    // Open the file in a new tab
                                                    window.open(fullDownloadUrl, '_blank');
                                                }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <p></p>
                                    )}

                                </Col>
                            </Row>
                        }
                        key="1"
                    >
                        {/* <Row>
                            <Col span={24}>
                                <div style={{ textAlign: 'right' }}>
                                    <Tooltip title="Upload">
                                        <Button
                                            icon={<UploadOutlined />}
                                            onClick={() => {
                                                // Handle file upload logic
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Downloads">
                                        <Button
                                            icon={<DownloadOutlined />}
                                            onClick={() => {
                                                // Handle download logic
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Comments">
                                        <Button
                                            icon={<CommentOutlined />}
                                            style={{ color: 'chocolate' }}
                                            onClick={() => {
                                                // Handle comments logic
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row> */}
                    </Panel>
                </div>
            </div>
        </>
    );
};

export default AssignmentDetail;
