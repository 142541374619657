import React, { useEffect, useRef, useState } from 'react';
// import { Player, ControlBar, PlayToggle } from 'video-react';
import { Player, ControlBar, PlayToggle , ReplayControl, ForwardControl, CurrentTimeDisplay, DurationDisplay, VolumeControl, PlaybackRateMenuButton, VolumeMenuButton  } from 'video-react';
import 'video-react/dist/video-react.css';
import { useConfig } from '../../ConfigContext';
import { useFeedback } from '../../Context/FeedbackContext';
import DocumentViewer from './DocumentViewer ';
import { toast } from 'react-toastify';
import videojs from 'video.js';


const VideoPlayer = ({ url, options, onReady }) => {
    const videoNode = useRef(null);
    const player = useRef(null);
  
    useEffect(() => {
      if (videoNode.current) {
        // Initialize Video.js player
        const playerOptions = {
          ...options,
          sources: [
            {
              src: url,
              type: 'video/mp4' // You can adjust this based on the file type
            }
          ]
        };
  
        player.current = videojs(videoNode.current, playerOptions, () => {
          if (onReady) onReady(player.current);
        });
  
        // Cleanup on component unmount
        return () => {
          if (player.current) {
            player.current.dispose();
          }
        };
      }
    }, [url, options, onReady]);
  
    return (
      <div data-vjs-player>
        <video ref={videoNode} className="video-js vjs-default-skin" />
      </div>
    );
};


const Universal = ({ docs }) => {
    const wrapperRef = useRef(null);
    const videoRefs = useRef({});
    const config = useConfig();
    const { Video, Audio, pdf, WYISIWYG, Image } = useFeedback();
    const [error, setError] = useState(null);

    const playerRef = useRef(null);
    const [playbackRate, setPlaybackRate] = useState(1.0);
  
    const handlePlaybackRateChange = (rate) => {
      setPlaybackRate(rate);
      if (playerRef.current) {
        playerRef.current.playbackRate = rate;
      }
    };

    const [videoUrl, setVideoUrl] = useState('https://path/to/your/video.mp4');

    const videoOptions = {
        autoplay: false,
        controls: true,
        preload: 'auto', // Adjust based on your preference
    };

    const handlePlayerReady = (player) => {
        console.log('Player is ready:', player);
    };



    const handleLoad = () => {
        setError(null); 
    };

    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        const handleKeyDown = (e) => {
            if (e.ctrlKey && (e.key === 's' || e.key === 'S')) {
                e.preventDefault();
                console.log("Ctrl+S disabled.");
            }
        };

        const handleInspectElement = (e) => {
            if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
                e.preventDefault();
                console.log("Attempted to inspect element inside docviewerwrapper.");
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        if (wrapperRef.current) {
            wrapperRef.current.addEventListener('mousedown', handleInspectElement);
        }

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('keydown', handleKeyDown);
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener('mousedown', handleInspectElement);
            }
        };
    
    }, []);

    useEffect(() => {
        console.log("docs:", docs);
    }, [docs]);

    const updateAnalytics = (videoId, newData) => {
        let analyticsData = JSON.parse(localStorage.getItem("VideoAnalytics")) || [];
        const index = analyticsData.findIndex(item => item.videoId === videoId);
        if (index !== -1) {
            analyticsData[index] = {
                videoId,
                ...newData
            };
        } else {
            analyticsData.push({
                videoId,
                ...newData
            });
        }
        localStorage.setItem("VideoAnalytics", JSON.stringify(analyticsData));  
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainderSeconds = Math.floor(seconds % 60);
        const formattedTime = `${minutes}:${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
        return formattedTime;
    };

    const handleSeek = (e, videoId) => {
        const currentTime = e.target.currentTime;
        updateAnalytics(videoId, { currentTime: formatTime(currentTime) });
    };

    const timeStringToSeconds = (timeString) => {
        if (!timeString) return 0;
        const [minutes, seconds] = timeString.split(':').map(parseFloat);
        return minutes * 60 + seconds;
    };

    const documents = Array.isArray(docs) ? docs : [docs];

    const docum = documents.map(doc => {
        if (doc.sessionAttachmentType == 4) {
            return { ...doc, isVideo: true };
        } else {
            return { ...doc, isVideo: false };
        }
    });

    const videos = docum.filter(doc => doc.isVideo);
    const otherDocuments = docum.filter(doc => !doc.isVideo);

    const getFileExtension = (path) => {
        const extension = path.split('.').pop(); // Split by dot and get the last part
        return extension;
    }



    const handleError = () => {
        const errorMessage = 'Failed to load the content. Please check your connection or try again later.';
        setError(errorMessage);
        alert(errorMessage); 
        console.log(errorMessage)
    };

    return (
        <div ref={wrapperRef} className={`docviewerwrapper overflow-y-auto ${videos.length > 0 ? "max-phone:h-[29vh]" : "h-[75vh]"} ${otherDocuments.length > 0 ? "max-phone:h-[55vh]" : ""} overflow-x-hidden h-[75vh] w-full p-2 border-b-2`}>
            <div className='h-full'>
                {otherDocuments?.length > 0 &&
                    otherDocuments?.map((item, index)=>(
                        item.sessionAttachmentType == 5 ?                         
                            <div className='h-full w-full'>
                                <img 
                                    className='w-full object-cover' 
                                    src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${item.globalFilePath}`} 
                                    alt={item.globalFilePath} 
                                />
                            </div>
                        :
                        item.sessionAttachmentType == 1 ?                         
                            // <DocViewer
                            //     style={{ height: "100%" }}
                            //     prefetchMethod='GET'
                            //     documents={otherDocuments?.map(doc => ({
                            //         uri:  `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${doc?.globalFilePath}`,
                            //         fileType: doc.sessionAttachmentType == 1 ? 'application/pdf' : 'other',
                            //         fileName:doc.globalFilePath
                            //     }))}
                            //     pluginRenderers={DocViewerRenderers}
                            //     sandbox="allow-scripts"
                            // />
                            <>   
                            {/* <div className='h-full  w-[50vw]'> */}
                            <div className='h-full  w-full'>
                                <DocumentViewer pdf={otherDocuments} />
                            </div>
                            </>
                            :
                            // item.sessionAttachmentType === "whiteboard" || item.sessionAttachmentType === "9" ?
                            item.sessionAttachmentType === "whiteboard"  ?
                            <div className='h-full  w-full'>
                                {error != null ? (
                                    <div className='z-[9999999999999999999999999999999999999999999999] bg-red-400'>Error loading content. Please try again later.</div>
                                ) : (
                                    <iframe onLoad={handleLoad} onError={handleError}  className='h-full w-full' src={item.Link} frameborder="0"></iframe>
                                )}

                            </div>
                            :

                            item.sessionAttachmentType == 8  ?
                            <div className='h-full  w-full flex flex-col justify-center items-center'>
                                <h1>{item?.ttsad_title}</h1>
                                <audio controls>
                                    <source 
                                        src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${item?.globalFilePath}`} 
                                        type="audio/mp3" />
                                </audio>
                            </div>
                            :
                        null
                    ))
                }
                {videos.map((video, index) => {
                    const initialStartTime = docs?.foundData?.currentTime !== undefined ? timeStringToSeconds(docs.foundData.currentTime) : 0;
                    const extention = getFileExtension(video?.globalFilePath)
                    console.log('extention' , extention)

                    // if(extention == 'flv'){
                    //     return (
                    //         <div key={index} className="video-player">
                    //             <VideoPlayer 
                    //                 url={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${video?.globalFilePath}`}
                    //                 options={videoOptions} onReady={handlePlayerReady} 
                    //             />
                    //         </div>
                    //     )
                    // } 

                    // if(extention == 'avi'){
                    //     return (
                    //         <div key={index} className="video-player">
                    //             <video                                 
                    //                 url={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${video?.globalFilePath}`}
                    //                 controls
                    //             ></video>
                    //         </div>
                    //     )
                    // } 

                    return (
                        <div key={video?.ttsam_globalcontentid} className='h-full w-full relative'>
                            <Player
                                playbackRate={playbackRate}
                                ref={playerRef}
                                sandbox="allow-scripts"
                                autoPlay
                                fluid={false}
                                width='100%'
                                height='100%'
                                poster={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${video?.globalFilePath}`}
                                src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${video?.globalFilePath}`}
                                disableDefaultControls={false}
                                onContextMenu={(e) => e.preventDefault()}
                                onTimeUpdate={(e) => {
                                    const currentTime = e.target.currentTime;
                                    const duration = e.target.duration;
                                    const videoId = video.ttsam_globalcontentid;

                                    if (currentTime > 0) {
                                        updateAnalytics(videoId, {
                                            currentTime: formatTime(currentTime),
                                            duration: formatTime(duration)
                                        });
                                        videoRefs.current[videoId] = { ...videoRefs.current[videoId], currentTime, duration };
                                    }
                                }}
                                onSeeking={(e) => handleSeek(e, video.ttsam_globalcontentid)}
                                startTime={initialStartTime}
                            >
                                <ControlBar autoHide={true}>
                                    <ReplayControl seconds={10} order={2.1} />
                                    <ForwardControl seconds={10} order={2.2} />
                                    <CurrentTimeDisplay order={3.1} />
                                    <DurationDisplay order={3.2} />
                                    <VolumeMenuButton order={3.3} />
                                    <PlaybackRateMenuButton
                                        rates={[0.5, 1, 1.5, 2]}
                                        onChange={handlePlaybackRateChange}
                                        order={7.1}
                                    />
                                    <PlayToggle />
                                </ControlBar>
                            </Player>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Universal;
